/**
 * 模块名称: 招聘管理
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
// import urls from '@/api/urls'
import {
  Table,
  Drawer,
  Form,
  Button,
  Input,
  Select,
  message,
  Icon,
  Popconfirm,
  Tooltip,
  Row,
  Col,
  DatePicker,
  Modal,
  Spin,
  Alert,
  Switch
} from 'antd'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'
import CorpFilter from '@/components/CorpFilter'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Addrecruit from './Addrecruit'
import CryptoJS from 'crypto-js'

const { Column } = Table
const { RangePicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option
let pageSize = 10
let currentPage = 1

let filter = {
  bizno: '',
  applyStaffName: '',
  companyId: '',
  departmentId: '',
  approvalStatus: '',
  emergencyLevel: '',
  addTimeMin: '',
  addTimeMax: '',
  type: 2
}
let params = {}

let selectedIds = ''
let distributionId = ''
let shouldMe = ''

const Recruit = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form

  const [statusValName, setStatusValName] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [formLoading, setformLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [expand, setExpand] = useState(false)
  const [batchDepartment, setBatchDepartment] = useState([])  //部门
  const [approvalStatusAll, setApprovalStatusAll] = useState([]) // 审批状态
  // const [emergencyAll, setEmergencyAll] = useState([]) //紧急程度
  const [visible, setVisible] = useState(false)
  const [visibleAllocation, setVisibleAllocation] = useState(false)
  const [filterInfoValue, setFilterInfoValue] = useState('')
  const [filterContent, setFilterContent] = useState([])
  const [statusVal, setStatusVal] = useState(undefined)
  const [editVisible, seteditVisible] = useState(false)
  const [addType, setAddType] = useState(0)
  const [editId, setEditId] = useState(null)
  const [authList, setAuthList] = useState([])
  const search = parseSearch(props.location.search)
  const [selectedCount, setSelectedCount] = useState(0)
  const [checkedIdArr, setCheckedIdArr] = useState([])
  const [distStaffList, setDistStaffList] = useState([])
  const [distStaffKey, setDistStaffKey] = useState('')
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [isDistribution, setIsDistribution] = useState(false)
  const [isShow, setIsShow] = useState(false)
  // const [distributionId, setDistributionId] = useState('')

  useEffect(() => {
    distributionId = ''
    shouldMe = ''
    onDepartment()
    onsetApprovalStatus()
    // onEmergencylevel()
    if (!history.location.state) {
      currentPage = search.page ? +search.page : 1
      pageSize = search.limit ? +search.limit : 10
      filter = {
        bizno: '',
        applyStaffName: '',
        companyId: '',
        departmentId: '',
        approvalStatus: '',
        emergencyLevel: '',
        addTimeMin: '',
        addTimeMax: '',
        type: 2
      }
    } else {
      currentPage = search.page ? +search.page : currentPage
      pageSize = search.limit ? +search.limit : pageSize
      filter.companyId = ''
      setTimeout(() => {
        setFieldsValue({
          bizno: filter.bizno !== '' ? filter.bizno : undefined,
          applyStaffName: filter.applyStaffName !== '' ? filter.applyStaffName : undefined,
          departmentId: filter.departmentId !== '' ? filter.departmentId : undefined,
          approvalStatus: filter.approvalStatus !== '' ? filter.approvalStatus : undefined,
          emergencyLevel: filter.emergencyLevel !== '' ? filter.emergencyLevel : undefined,
          addTime: filter.addTimeMin !== '' ? [moment(filter.addTimeMin), moment(filter.addTimeMax)] : undefined,
          type: 2
        })
      }, 1000)
    }
    getList()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const getList = () => {
    setLoading(true)
    api.getStaffRecruitmentList({ ...filter, limit: pageSize, page: currentPage, acceptStaffId: distributionId, shouldMe })
      .then(data => {
        setLoading(false)
        setList(data.list)
        setCount(data.count)
      })
      .catch(() => setLoading(false))
  }

  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        let arr = Object.values(vals)
        if (arr.some(item => item)) {
          setSelectedCount(0)
          setCheckedIdArr([])
          setSelectedRowKeys([])
        }
        currentPage = 1
        history.replace(match.path)
        filter = {
          ...filter,
          ...vals,
          addTimeMin: vals.addTime ? vals.addTime[0].format('YYYY-MM-DD') : '',
          addTimeMax: vals.addTime ? vals.addTime[1].format('YYYY-MM-DD') : ''
        }
        getList()
      }
    })
  }

  // 重置
  const onReset = () => {
    resetFields()
    filter = {
      bizno: '',
      applyStaffName: '',
      companyId: filter.companyId,
      departmentId: '',
      approvalStatus: '',
      emergencyLevel: '',
      addTimeMin: '',
      addTimeMax: '',
      type: 2
    }
    setStatusVal(undefined)
    setStatusValName(undefined)
    currentPage = 1
    history.replace(match.path)
    getList()
  }

  // 部门
  const onDepartment = () => {
    api.getSelectDepartmentList({
      companyId: filter.companyId,
      limit: global.paramsLimit
    }).then(data => {
      setBatchDepartment(data.list)
    }).catch(() => setLoading(false))
  }

  // 审批状态
  const onsetApprovalStatus = () => {
    api.getAuditStatusList({ type: '2'}).then(data => {
      setApprovalStatusAll(data)
    })
  }

  // 紧急程度
  // const onEmergencylevel = () => {
  //   api.getCommon({ type: 'recruit_emergency_level' }).then(data => {
  //     setEmergencyAll(data)
  //   })
  // }


  // 补充资料(编辑)
  const onEdit = (data) => {
    seteditVisible(true)
    setAddType(1)
    setEditId(data.id)
  }

  //招聘申请
  const onAddRole = () => {
    setAddType(0)
    seteditVisible(true)
  }

  // 删除
  const onDelete = (id) => {
    api.delRecruitmentupdateIsDel({ id }, true).then(() => {
      message.success('删除成功')
      getList()
    })
  }


  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getList()
  }

  const onChangeCorp = (data) => {
    setBatchDepartment([])
    setSelectedCount(0)
    setCheckedIdArr([])
    setSelectedRowKeys([])
    setFieldsValue({'departmentId': undefined})
    filter.departmentId = ''
    filter.companyId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  const onToggle = () => {
    setExpand(!expand)
  }

  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item !== undefined)) {
          params = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              if (k === 'addTime') {
                params[k] = [moment(vals[k][0]).format('YYYY-MM-DD'), moment(vals[k][1]).format('YYYY-MM-DD')]
              } else {
                params[k] = vals[k]
              }
            }
          }
          setVisible(true)
        } else {
          message.error('当前没有设置筛选条件')
        }
      }
    })
  }

  const onChecked = (value, val2) => {
    setStatusVal(value)
    setStatusValName(val2.props.children.props.children[0])
    let data = filterContent[value].content
    setFieldsValue({
      bizno: data.bizno ? data.bizno : undefined,
      applyStaffName: data.applyStaffName ? data.applyStaffName : undefined,
      departmentId: data.departmentId ? data.departmentId : undefined,
      approvalStatus: data.approvalStatus ? data.approvalStatus : undefined,
      emergencyLevel: data.emergencyLevel ? data.emergencyLevel : undefined,
      addTime: data.addTime ? [moment(data.addTime[0], 'YYYY-MM-DD'), moment(data.addTime[1], 'YYYY-MM-DD')] : undefined
    })
  }
  //弹出框确定
  const HandleOk = () => {
    api.saveSearchStorage({
      name: filterInfoValue,
      key: location.pathname,
      content: params
    }).then(data => {
      setVisible(false)
      message.success('添加成功')
    })
  }
  const onChangeGetFilter = (val) => {
    //console.log(val)
    //下拉框搜索内容
    api.getSearchStorage({ key: location.pathname }).then(data => {
      setFilterContent(data.list)
    })
  }

  const HandleCancel = () => {
    setVisible(false) //弹出框内取消
  }

  const FilterInfoChange = (e) => {
    setFilterInfoValue(e.target.value) //弹框input值
  }

  const closeDrawer = () => {
    seteditVisible(false)
  }

  const onDropdownVisible = () => {
    api.getSelectDepartmentList({ limit: global.paramsLimit, companyId: filter.companyId }).then(data => setBatchDepartment(data.list)) //部门
  }

  //分配招聘人员
  const onAllocationRole = () => {
    if (selectedCount == 0) {
      return message.warning('请选择要分配的招聘数据')
    }
    //console.log(checkedIdArr)
    if (!(checkedIdArr.every(item => item.needDist == true))) {
      return message.warning('只能选择人力总监审核通过数据！')
    }
    setVisibleAllocation(true)
    if (distStaffKey != '') setDistStaffKey('')
    api.getStaffRecruitmentDistStaffList().then(res => {
      // console.log(res)
      setDistStaffList(res)
    })
  }

  //分配招聘人员弹窗内列表
  const onDistStaff = (key) => {
    setDistStaffKey(key)
  }

  //分配招聘人员确定弹窗
  const batchHandleOk = () => {
    // console.log('distStaffKey', distStaffKey)
    if (distStaffKey == '') {
      return message.warning('请选择分配人')
    }
    api.saveStaffRecruitmentDistribution({ ids: selectedIds, distStaff: distStaffKey})
    .then(res => {
      message.success('操作成功') 
      setVisibleAllocation(false)
    })
    .catch(err => console.log(err))
  }

  //分配招聘人员取消弹窗
  const batchHandleCancel = () => {
    setVisibleAllocation(false)
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      //console.log(`selectedRowKeys` , selectedRowKeys, 'selectedRows: ', selectedRows)
      setSelectedRowKeys(selectedRowKeys)
      setSelectedCount(selectedRowKeys.length)
      setCheckedIdArr(selectedRows)
      selectedIds = selectedRowKeys.join(",")
    },
    onSelect: (record, selected, selectedRows) => {
      //console.log(record, selected, selectedRows)
      //let arr = []
      if (!selected) {
        for (let i = 0; i < checkedIdArr.length; i++) {
          if (checkedIdArr[i] == record) {//eslint-disable-line
            checkedIdArr.splice(i, 1)
            i--
          }
        }
      } else {
        checkedIdArr.push(record)
      }
      let arrNew = [...new Set(checkedIdArr)]
      setCheckedIdArr(arrNew)
      // setSelectedCount(arrNew.length)
      // selectedIds = arrNew
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
    //全选一列（默认十条）
    onSelectAll: (selected, selectedRows, changeRows) => {
      //console.log(selected, selectedRows, changeRows)
      let arr = []
      changeRows.forEach(item => {
        arr.push(item)
      })
      if (selected) {
        setCheckedIdArr([...new Set([...checkedIdArr, ...arr])])
      } else {
        setCheckedIdArr(checkedIdArr.filter(item => !arr.includes(item)))
      }
    },
    selectedRowKeys: selectedRowKeys
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  //待分配
  const onDistribution = (checked) =>  {
    currentPage = 1
    // console.log('待分配', checked)
    setIsDistribution(checked)
    if (checked) {
      distributionId = 1
    } else {
      distributionId = ''
    }
    setTimeout(() => {
      getList()
    }, 600)
  }

  const onSetShouldMe = (checked) => {
    currentPage = 1
    setIsShow(checked)
    if (checked) {
      shouldMe = 1
    } else {
      shouldMe = ''
    }
    setTimeout(() => {
      getList()
    }, 600)
  }

  return (
    <>
      <CorpFilter onChange={onChangeCorp} />
      <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo">
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
              <Col span={6}>
                <FormItem label="业务编号" labelCol={{ span: 8 }}>
                  {getFieldDecorator('bizno')(<Input placeholder="请输入业务编号" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="申请人" labelCol={{ span: 8 }}>
                  {getFieldDecorator('applyStaffName')(<Input placeholder="请输入员工姓名" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="部门" labelCol={{ span: 8 }}>
                  {getFieldDecorator('departmentId')(
                    <Select placeholder="全部"
                      dropdownMatchSelectWidth={false}
                      onDropdownVisibleChange={bool => bool && onDropdownVisible()}
                      showSearch={true}
                      optionFilterProp="children"
                    >
                      {batchDepartment.map(v =>{
                        return !filter.companyId.length ? <Option title={v.name + '（' + v.companyName + '）'} key={v.id}>{v.name}（{v.companyName}）</Option> : <Option title={v.name} key={v.id}>{v.name}</Option>
                      })}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="审批状态" labelCol={{ span: 8 }}>
                  {getFieldDecorator('approvalStatus')(
                    <Select placeholder="全部" 
                      dropdownMatchSelectWidth={false}
                      showSearch={true}
                      optionFilterProp="children"
                    >
                      {approvalStatusAll.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              {/* <Col span={5}>
                <FormItem label="紧急程度" labelCol={{ span: 8 }}>
                  {getFieldDecorator('emergencyLevel')(
                    <Select placeholder="全部">
                      {emergencyAll.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col> */}

              <Col span={6}>
                <FormItem label="申请时间" labelCol={{ span: 8 }}>
                  {getFieldDecorator('addTime')(<RangePicker allowClear={false} span={12}/>)}
                </FormItem>
              </Col>
            </Row>
          </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle} style={{ cursor: 'pointer', fontSize: '12px', color: '#1890ff' }}>
            <span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
          </span>
        </div>

        <div className="recruit-search-btns">
          <div className={`search-btns ${expand ? '' : 'hide'}`}>
            <h4 className="filter-title">已保存筛选方案：</h4>
            <div className="filter-select">
              <Select
                style={{ display: 'inline-block', width: '160px' }}
                placeholder="请选择"
                value={statusVal}
                onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)}
                onSelect={onChecked}
                dropdownMatchSelectWidth={false}
              >
                {
                  filterContent.map((item, index) => 
                    <Option value={index} key={index}>
                      <div className="closeStyBtn">
                        {item.name}
                        {statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                          e.stopPropagation()
                          onSearchDelete(item.id)
                        }} /></span> : null}
                      </div>
                    </Option>
                  )
                }
              </Select>
            </div>
            <Button className="filter-savebtn" onClick={onSaveFilterTerm}>保存筛选条件</Button>
          </div>
          <Switch checkedChildren="待审批" unCheckedChildren="待审批" checked={isShow} onClick={onSetShouldMe} style={{marginRight: 8}} />
          <Switch checkedChildren="待分配" unCheckedChildren="待分配" checked={isDistribution} onClick={onDistribution}/>
          <Button style={{ marginLeft: 15 }} onClick={onReset}>重置</Button>
          <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
          <Modal
            title="保存筛选条件"
            visible={visible}
            onOk={HandleOk}
            onCancel={HandleCancel}
          >
            <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={FilterInfoChange} />
          </Modal>
        </div>
      </Form>
      <div className="line"></div>
      <div className="add-wrap">
        <Auth auths={authList} code="staff-attendance-distribution">
          <Button type="primary" icon="solution" onClick={onAllocationRole} style={{ marginRight: '15px'}}>分配招聘人员</Button>
        </Auth>
        <Auth auths={authList} code="staff-attendance-apply">
          <Button type="primary" icon="solution" onClick={onAddRole}>招聘申请</Button>
        </Auth>
      </div>
      <Alert className="count-alert" message={
        <>
          <span>总计：{count}条数据</span>
          <span style={{ marginLeft: 20 }}>已选<span style={{color: '#168FFF'}}>{selectedCount}</span>条</span>
        </>
      } type="info" showIcon/>
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 1400 }}
        onChange={onChangeTable}
        rowSelection={rowSelection}
      >
         <Column title="申请人"
          dataIndex="applyStaffName"
          fixed="left"
          width={120}
          render={(companyName, record) => <Link to={`${match.path}/detail?id=${record.id}&new=1&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{companyName}</Link>}
        />
        <Column title="招聘人数" dataIndex="applyRecruitNum" fixed="left" width={80} />
        <Column title="申请部门" dataIndex="departmentName" fixed="left" width={180} />
        {/* <Column title="增补岗位" dataIndex="name" fixed="left" width={120} />
        <Column title="职务" dataIndex="jobStationName" fixed="left" width={150} /> */}
        {/* <Column title="紧急程度" dataIndex="emergencyLevelCn" /> */}
        <Column title="岗位" dataIndex="name" />
        <Column title="招聘状态" dataIndex="approvalStatusCn" />
        <Column title="岗位类别" dataIndex="jobType" />
        <Column title="希望到岗日期" dataIndex="arrivalDate" />
        <Column title="申请时间" dataIndex="addTime" />
        <Column title="反馈时间" dataIndex="feedbackTime" />
        <Column title="业务编号" dataIndex="bizno" />
        <Column title="操作" key="set" fixed="right" width={100} render={(text, record) => (
          <>
            <Tooltip title="在新页面打开详情页" placement="bottom">
              <Link target='_blank' to={`${match.path}/detail?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>
                <Icon type="folder-open" style={{ color: '#1890FF', fontSize: '16px', marginRight: '8px' }} />
              </Link>
            </Tooltip>
            {
              (record.userAuth && record.userAuth.allowReplenish) || (record.userAuth && record.userAuth.allowRakeUp) ?
                <Tooltip title="编辑" placement="bottom">
                  <Icon className="operate-icon" style={{ color: '#1890ff' }} type="edit" onClick={() => onEdit(record)} />
                </Tooltip>
                : null
            }
            {' '}
            {
              record.userAuth && record.userAuth.allowDelete ?
                <Popconfirm title="确认删除吗？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                  <Tooltip title="删除" placement="bottom">
                    <Icon className="operate-icon" style={{ color: 'red' }} type="delete" />
                  </Tooltip>
                </Popconfirm> : null
            }

          </>
        )} />
      </Table>
      <Drawer
        title="招聘申请"
        width={600}
        onClose={closeDrawer}
        visible={editVisible}
        destroyOnClose={true}
      >
        <Spin spinning={formLoading}>
          <Addrecruit
            setformLoading={setformLoading}
            seteditVisible={seteditVisible}
            getList={getList}
            addType={addType}
            editId={editId}
          />
        </Spin>
      </Drawer>
      <Modal
          title='分配招聘人员'
          visible={visibleAllocation}
          onOk={batchHandleOk}
          onCancel={batchHandleCancel}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <div>员工姓名：</div>
          <Select 
            style={{ width: 180 }} 
            placeholder="请选择" 
            onSelect={onDistStaff} 
            showSearch={true} 
            optionFilterProp="children" 
            value={distStaffKey}
          >
            {
              distStaffList.map((v, i) => {
                return <Option value={v.id} key={v.id}>{v.name.staffName}</Option>
              })
            }
          </Select>
        </div>
      </Modal>
    </>
  )
}

export default Form.create()(Recruit)