/*
 * @Author: zhuyan 
 * @Date: 2021-12-06 14:07:
 * 我的资产
 */
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import HardwareMy from './HardwareMy'
import MobileMy from './MobileMy'
import SystemMy from './SystemMy'
import SoftwareMy from './SoftwareMy'

const MyAssetsIndex = ({ match, history }) => {

	return (
		<Switch>
			<Route path={match.path + '/hardware'} component={HardwareMy} />
			<Route path={match.path + '/mobile'} component={MobileMy} />
			<Route path={match.path + '/system'} component={SystemMy} />
			<Route path={match.path + '/software'} component={SoftwareMy} />
			<Route component={SystemMy} />
		</Switch>
	)
}

export default MyAssetsIndex