/**
 * 模块名称: 转岗管理模块
 * @author zhuyan@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import JobTransfer from './JobTransfer'
import JobTransferDetail from './JobTransferDetail'

const JobTransferIndex = ({ match, history }) => {

  return (
    <div>
      <Switch>
        <Route path={match.path + '/detail'} component={JobTransferDetail} />
        <Route component={JobTransfer} />
      </Switch>
    </div>
  )
}

export default JobTransferIndex