/**
 * 模块名称: 晋升管理详情页
 * @author zhuyan@372163.com
 */
import React, { useState, useEffect } from 'react'
import {
  Descriptions,
  Steps,
  Button,
  Modal,
  Input,
  Table,
  Icon,
  message,
  Spin
} from 'antd'
import api from '@/api'
import { parseSearch, debounce } from '@/utils'
import CryptoJS from 'crypto-js'

const dItem = Descriptions.item
const { Step } = Steps
const { Column } = Table

const PromotionDetail = (props) => {
  const { history } = props
  const [detailData, setDetailData] = useState({})
  const [detailDataTwo, setDetailDataTwo] = useState({})
  const [oldDetailDataTwo, setOldDetailDataTwo] = useState({})
  const [oldDetailData, setOldDetailData] = useState({}) //原来数据
  const [detailDataId, setdetailDataId] = useState('')
  const [statusList, setStatusList] = useState([])
  const [logConList, setLogConList] = useState([])
  const [buttonAuthList, setButtonAuthList] = useState({})
  const [passModalVisible, setModalVisible] = useState(false) //确认弹框
  const [turnModalVisible, setTurnModalVisible] = useState(false) //驳回弹框
  const [textAreaVal, setTextAreaVal] = useState('') //判断框是否为空
  const [textDisplay, setTextDisplay] = useState(true) //是否提示必选
  const [pasDesc, setPasDesc] = useState('')  //通过意见
  const [showFile, setShowFile] = useState([]) //附件
  const [oldshowFile, setoldFile] = useState([]) //更新前附件
  //const [detailDataTop, setDetailDataTop] = useState({})
  const search = parseSearch(props.location.search)
  const [detailLoading, setDetailLoading] = useState(true)

  useEffect(() => {
    let plaintext = search.sign
    let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()

    if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
      api.getStaffPromotionInfo({ id: search.id }).then(data => {
        if (search.msgId) {
          api.setMessageReaded({ ids: [search.msgId] })
        }
        setDetailData(data)
        setDetailDataTwo(data.staffInfo)
        setOldDetailDataTwo(data.oldData && data.oldData.staffInfo ? data.oldData.staffInfo : data.staffInfo)
        setOldDetailData(data.oldData ? data.oldData : data) //更新前数据
        setdetailDataId(data.id)
        setStatusList(data.procedureStatusList) // 审批进度
        setLogConList(data.logList) // 审批日志
        setButtonAuthList(data.buttonAuthList) // 审批权限
        setShowFile(data.attachmentCn) //附件
        setoldFile(data.oldData && data.oldData.attachmentCn ? data.oldData.attachmentCn : data.attachmentCn)
        setDetailLoading(false)
      })
    } else {
      message.error('没有本页访问权限')
      setTimeout(() => {
        history.go(-2)
      }, 1000)
    }
  }, [])

  // 通过
  const onBtnPassShow = () => {
    setModalVisible(true)
  }

  const onPassCancel = () => {
    setModalVisible(false)
  }

  const onPassDescChange = (e) => {
    setPasDesc(e.target.value)
  }

  const onApprove = () => {
    api.getstaffPromotionPass({
      id: detailDataId,
      remark: pasDesc
    }).then(data => {
      api.getStaffPromotionInfo({ id: search.id }, true).then(data => {
        setDetailData(data)
        setDetailDataTwo(data.staffInfo)
        setOldDetailDataTwo(data.oldData && data.oldData.staffInfo ? data.oldData.staffInfo : data.staffInfo)
        setOldDetailData(data.oldData ? data.oldData : data) //更新前数据
        setdetailDataId(data.id)
        setStatusList(data.procedureStatusList) // 审批进度
        setLogConList(data.logList) // 审批日志
        setButtonAuthList(data.buttonAuthList) // 审批权限
        setShowFile(data.attachmentCn) //附件
        setoldFile(data.oldData && data.oldData.attachmentCn ? data.oldData.attachmentCn : data.attachmentCn)
        // if(data.oldData && data.oldData.attachmentCn) setoldFile(data.oldData.attachmentCn)
      }) // 确认通过后
    })
    setModalVisible(false)
  }

  // 驳回
  const onBtnTurnShow = () => {
    setTurnModalVisible(true)
    setTextDisplay(true)
  }

  const onTurnCancel = () => {
    setTurnModalVisible(false)
  }

  const onTurnApprove = () => {
    if (buttonAuthList.reject_msg_require) {
      if (textAreaVal.trim() === '') {
        setTextDisplay(false)
        return
      }
    }
    api.getstaffPromotionReject({
      id: detailDataId,
      remark: textAreaVal
    }).then(data => {
      api.getStaffPromotionInfo({ id: search.id }, true).then(data => {
        setDetailData(data)
        setDetailDataTwo(data.staffInfo)
        setOldDetailDataTwo(data.oldData && data.oldData.staffInfo ? data.oldData.staffInfo : data.staffInfo)
        setOldDetailData(data.oldData ? data.oldData : data) //更新前数据
        setdetailDataId(data.id)
        setStatusList(data.procedureStatusList) // 审批进度
        setLogConList(data.logList) // 审批日志
        setButtonAuthList(data.buttonAuthList) // 审批权限
        setShowFile(data.attachmentCn) //附件
        if (data.oldData && data.oldData.attachmentCn) setoldFile(data.oldData.attachmentCn)
      }) // 确认驳回
    })
    setTurnModalVisible(false)
  }

  const onDescChange = (e) => {
    setTextAreaVal(e.target.value)
    setTextDisplay(true)
  }

  // 驳回样式
  const turnNode = () => {
    return <>
      确认驳回 <Icon type="close-circle" theme="twoTone" twoToneColor="#DC143C" />
    </>
  }

  // 同意样式
  const agreeNode = () => {
    return <>
      确认通过 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
    </>
  }

  // 转上级
  const onTurnUp = () => {
    api.setStaffPromotionTurnUp({
      id: detailDataId,
      remark: ''
    }).then(data => {
      history.push('/hr/promotion')
    }).catch()
  }

  // 补充资料
  const onReplenish = () => {
    api.setStaffPromotionReplenish({
      id: detailDataId,
      remark: ''
    }).then(data => {
      history.push('/hr/promotion')
    }).catch()
  }

  return (
    <Spin spinning={detailLoading}>
      <>
        <div className="pro-detail">
          <Descriptions title="晋升信息" layout="horizontal " className="pro-detail-title">
            <dItem label="晋升人">{detailData.staffName}</dItem>
          </Descriptions>
          <Descriptions title="晋升申请流程" className="pro-detail-smailtitle"></Descriptions>
          <div className="promotionProcess" >
            <Steps>
              {
                statusList.map((item, index) => {
                  let status = 'wait'
                  if (item.time && item.time.length > 1) {
                    status = 'process'
                  }
                  return <Step status={status}
                    key={index}
                    value={item.time}
                    title={item.nodeName}
                    description={
                      <>
                        {item.persons && <div title={`${item.persons.map((item) => item)}`}>{`${item.persons.map((item) => item)}`}</div>}
                        <div title={item.statusName}>{item.statusName}</div>
                        <div title={item.time}>{item.time}</div>
                      </>
                      // `${item.persons.map((item)=>item)} ${item.statusName} ${item.time}`
                    }
                  />
                })
              }
            </Steps>
          </div>
          <div className="line-box"></div>
          <Descriptions title="申请信息"
            layout="vertical"
            className="pro-detail-smailtitle pro-apply"
          >
            <dItem label="姓名">
              <>
                {detailData.staffName}
                {oldDetailData.staffName !== detailData.staffName ? <div className="change-text">变更前：
              {oldDetailData.staffName}</div> : ''}
              </>
              {/* { ()=>{
              if (oldDetailData.applyStaffName && oldDetailData.applyStaffName !== detailData.applyStaffName){
                return <div>变更前：{oldDetailData.applyStaffName}</div>
              }
            }} */}
            </dItem>

            <dItem label="原所属公司">
              <>
                {detailData.oldCompanyName}
                {oldDetailData.oldCompanyName !== detailData.oldCompanyName ? <div className="change-text">变更前：{oldDetailData.oldCompanyName}</div> : null}
              </>
            </dItem>
            <dItem label="原所属部门">
              <>
                {detailData.oldDepartmentName}
                {oldDetailData.oldDepartmentName !== detailData.oldDepartmentName ? <div className="change-text">变更前：{oldDetailData.oldDepartmentName}</div> : null}
              </>
            </dItem>
            <dItem label="原职级">
              <>
                {detailData.oldJobPositionCn}
                {oldDetailData.oldJobPositionCn !== detailData.oldJobPositionCn ? <div className="change-text">变更前：{oldDetailData.oldJobPositionCn}</div> : null}
              </>
            </dItem>
            <dItem label="原岗位级别">
              <>
                {detailData.oldJobStationCn}
                {oldDetailData.oldJobStationCn !== detailData.oldJobStationCn ? <div className="change-text">变更前：{oldDetailData.oldJobStationCn}</div> : null}
              </>
            </dItem>
            <dItem label="原直接主管">
              <>

                {detailData.oldLeaderName}
                {oldDetailData.oldLeaderName !== detailData.oldLeaderName ? <div className="change-text">变更前：{oldDetailData.oldLeaderName}</div> : null}
              </>
            </dItem>
            <dItem label="员工编号">
              {detailDataTwo.staffNo}
              {oldDetailDataTwo.staffNo !== detailDataTwo.staffNo ? <div className="change-text">变更前：{oldDetailDataTwo.staffNo}</div> : null}
            </dItem>
            <dItem label="毕业院校">
              {detailDataTwo.schoolName}
            </dItem>
            <dItem label="所学专业">
              {detailDataTwo.majorName}
            </dItem>
            <dItem label="入职日期">
              {detailDataTwo.entryDate}
            </dItem>
            <dItem label="工作年限（月）" span={2}>
              {detailDataTwo.entryMonthNum}
            </dItem>
            <dItem label="晋升后公司">
              <>
                {detailData.newCompanyName}
                {oldDetailData.newCompanyName !== detailData.newCompanyName ? <div className="change-text">变更前：{oldDetailData.newCompanyName}</div> : null}
              </>
            </dItem>
            <dItem label="晋升后部门">
              <>
                {detailData.newDepartmentName}
                {oldDetailData.newDepartmentName !== detailData.newDepartmentName ? <div className="change-text">变更前：{oldDetailData.newDepartmentName}</div> : null}
              </>
            </dItem>
            <dItem label="晋升后直接主管">
              <>
                {detailData.managerStaffName}
                {oldDetailData.managerStaffName !== detailData.managerStaffName ? <div className="change-text">变更前：{oldDetailData.managerStaffName}</div> : null}
              </>
            </dItem>
            <dItem label="晋升后职级">
              <>
                {detailData.newJobPositionCn}
                {oldDetailData.newJobPositionCn !== detailData.newJobPositionCn ? <div className="change-text">变更前：{oldDetailData.newJobPositionCn}</div> : null}
              </>
            </dItem>
            <dItem label="晋升后岗位级别" span={2}>
              <>
                {detailData.newJobStationCn ? detailData.newJobStationCn : '--'}
                {oldDetailData.newJobStationCn !== detailData.newJobStationCn ? <div className="change-text">变更前：{oldDetailData.newJobStationCn}</div> : null}
              </>
            </dItem>
            <dItem label="附件" span={3}>
              <>
                {
                  showFile && showFile.length >= 1 ? showFile.map((item, i) => {
                    return <div key={i}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></div>
                  }) : '无'
                }
                {JSON.stringify(showFile) && JSON.stringify(showFile) !== JSON.stringify(oldshowFile) ? <div className="change-text">变更前：
              {
                    oldshowFile && oldshowFile.length >= 1 ? oldshowFile.map((item, i) => {
                      return <div key={i}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></div>
                    }) : '无'
                  }</div> : null}
              </>
            </dItem>
            <dItem label="备注">
              <>
                {detailData.remark ? detailData.remark : '无'}
                {oldDetailData.remark !== detailData.remark ? <div className="change-text">变更前：{oldDetailData.remark}</div> : null}
              </>
            </dItem>
          </Descriptions>
          <div className="line-box"></div>
          <Descriptions
            title="审批记录"
            layout="horizontal"
            className="pro-detail-smailtitle"
          >
          </Descriptions>
          <Table
            dataSource={logConList}
            rowKey="id"
            pagination={false}
          >
            <Column title="姓名" dataIndex="userInfo.staffName" />
            <Column title="时间" dataIndex="addTime" />
            <Column title="状态" dataIndex="status" />
            <Column title="审批意见" dataIndex="chargeMsg" />
          </Table>
          <div className="btn-setting">
            <Button hidden={!buttonAuthList.replenish} onClick={debounce(() => { onReplenish() }, 3000)}>补充资料</Button>
            <Button hidden={!buttonAuthList.return_up} onClick={debounce(() => { onTurnUp() }, 3000)}>转上级</Button>
            <Button hidden={!buttonAuthList.hasChargerPower} onClick={onBtnTurnShow}>驳回</Button>
            <Modal
              title={turnNode()}
              // title="确认驳回"
              visible={turnModalVisible}
              onOk={debounce(() => {onTurnApprove()}, 3000)}
              onCancel={onTurnCancel}
              footer={[
                <Button key="back" onClick={onTurnCancel}>取消</Button>,
                <Button key="submit" type="primary" onClick={debounce(() => {onTurnApprove()}, 3000)}>确定</Button>
              ]}
            >
              <div>
                <span>审批意见:</span>
                <Input.TextArea placeholder="请输入驳回审批意见" value={textAreaVal} onChange={onDescChange} maxLength={100} />
              </div>
              <span className="poine-text" hidden={textDisplay}>请填写审批意见</span>
            </Modal>
            <Button hidden={!buttonAuthList.hasChargerPower} type="primary" onClick={onBtnPassShow}>通过</Button>
            <Modal
              // title="确认通过"
              title={agreeNode()}
              visible={passModalVisible}
              onOk={debounce(() => {onApprove()}, 3000)}
              onCancel={onPassCancel}
              footer={[
                <Button key="back" onClick={onPassCancel}>取消</Button>,
                <Button key="submit" type="primary" onClick={debounce(() => {onApprove()}, 3000)}>确定</Button>
              ]}
            >
              <div>
                <span>审批意见:</span>
                <Input.TextArea placeholder="请输入通过审批意见" value={pasDesc} onChange={onPassDescChange} maxLength={100} />
              </div>
            </Modal>
          </div>
        </div>
      </>
    </Spin>
  )
}

export default PromotionDetail