import React, { useRef, useState } from 'react';
import { Icon, Upload, Modal, Popconfirm, message } from 'antd';
import { upLoadModule } from '@/utils/common';

export default function AgreementFiles({
  defaultValue = [],
  edit = false,
  onConfirm,
}) {
  const [value, setValue] = useState(defaultValue);
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const draggerRef = useRef(null);
  const [fileList, setFileList] = useState([]);

  const uploadFiles = upLoadModule({
    fileList: fileList,
    setFileList: setFileList,
    draggerRef: draggerRef,
    accept: '.gif, .png, .jpg, .ppt, .pdf',
    limitNum: 20,
  });

  const onDelete = (i) => {
    if (loading) {
      return false;
    }
    if (onConfirm) {
      if (value.length <= 1) {
        message.warn('该项信息不能为空');
        return false;
      }
      setLoading(true);
      value.splice(i, 1);
      onConfirm(value, onCancel);
    }
  };

  const onEdit = (i) => {
    setVisible(true);
    setIndex(i);
  };

  const onOk = () => {
    if (onConfirm) {
      setLoading(true);
      let files = fileList.map((item) => ({
        name: item.name,
        url: item.url,
      }));
      value.splice(index, 1);
      value.splice(index, 0, ...files);
      console.log(value);
      onConfirm(JSON.stringify(value), onCancel);
    }
    console.log(fileList);
  };
  const onCancel = (rest = false) => {
    if (rest) {
      setValue(defaultValue);
    }
    setVisible(false);
    setLoading(false);
    setFileList([]);
    setIndex(0);
  };

  if (defaultValue.length <= 0) {
    return '无';
  }
  return (
    <>
      {defaultValue.map((item, i) => {
        return (
          <div key={i}>
            <a
              key='url'
              href={item.url}
              target='_blank'
              rel='noopener noreferrer'
              style={{ marginRight: '10px' }}
            >
              {item.name}
            </a>
            {edit ? (
              <>
                <a
                  key='edit'
                  style={{ marginRight: '10px' }}
                  onClick={() => onEdit(i)}
                >
                  修改
                </a>
                <Popconfirm
                  key='delete'
                  title='请确认是否删除，删除后不可恢复。'
                  onConfirm={() => onDelete(i)}
                  okText='确认'
                  cancelText='取消'
                >
                  <a>删除</a>
                </Popconfirm>
              </>
            ) : null}
          </div>
        );
      })}
      <Modal
        title='修改附件'
        visible={visible}
        onCancel={onCancel}
        onOk={onOk}
        okButtonProps={{
          disabled: fileList.length < 1,
        }}
        loading={loading}
        destroyOnClose
      >
        <div className='dragger-box' ref={draggerRef}>
          <Upload.Dragger {...uploadFiles}>
            <p className='ant-upload-drag-icon'>
              <Icon type='inbox' />
            </p>
            <p className='ant-upload-text'>点击或将文件拖拽到这里上传</p>
            <p className='ant-upload-hint'>
              支持扩展名：.gif, .png, .jpg, .ppt, .pdf 单个文件大小不超过5M。
            </p>
          </Upload.Dragger>
        </div>
      </Modal>
    </>
  );
}
