/**
 * 模块名称: 我的资产 => 手机号
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import moment from 'moment'
import {
	Table,
	Form,
	Input,
	DatePicker,
	Select,
	message,
	Row,
	Col,
	Icon
} from 'antd'
import { parseSearch } from '@/utils'
import CorpFilter from '@/components/CorpQuanQian'
import BtnGroup from '@/components/BtnGroup'

const { Column } = Table
const FormItem = Form.Item
const { Option } = Select
let pageSize = 10
let currentPage = 1
let apiOperateTimer = true

const { RangePicker } = DatePicker

//所属公司筛选
let filter = {
	companyId: ''
}

//筛选初始值
let exporfilter = {
	number: '',
	phone: '',
	userName: '',
	userNumber: '',
	registerUserName: '',
	registerUserNumber: '',
	userGroupId: '',
	userDepartmentId: '',
	checkinStartTime: '',
	checkinEndTime: '',
	receiveStartTime: '',
	receiveEndTime: ''
}

const MobileMy = (props) => {
	const { match, location, history } = props
	const { getFieldDecorator, resetFields, setFieldsValue, validateFields } = props.form
	const [loading, setLoading] = useState(false)
	const [list, setList] = useState([])
	const [count, setCount] = useState(0)
	const [groupList, setGroupList] = useState([])//获取事业群
	const [departmentList, setDepartmentList] = useState([])//获取部门
	// const [authList, setAuthList] = useState([])
	const [expand, setExpand] = useState(false)//展开收起
	const [alterationUnfoldPack, setAlterationUnfoldPack] = useState(true)
  const [alterationTextUnfoldPack, setAlterationTextUnfoldPack] = useState(true)

	const search = parseSearch(location.search)

	useEffect(() => {
		filter = {
			companyId: ''
		}
		if (!history.location.state) {
			currentPage = search.page ? +search.page : 1
			pageSize = search.limit ? +search.limit : 10
			exporfilter = {
				number: '',
				phone: '',
				userName: '',
				userNumber: '',
				registerUserName: '',
				registerUserNumber: '',
				userGroupId: '',
				userDepartmentId: '',
				checkinStartTime: '',
				checkinEndTime: '',
				receiveStartTime: '',
				receiveEndTime: ''
			}
			getAssetPhoneNumberOwnerList()
		} else {
			currentPage = search.page ? +search.page : currentPage
			pageSize = search.limit ? +search.limit : pageSize
			setTimeout(() => {
				setFieldsValue({
					number: exporfilter.number !== '' ? exporfilter.number : undefined,
					phone: exporfilter.phone !== '' ? exporfilter.phone : undefined,
					userName: exporfilter.userName !== '' ? exporfilter.userName : undefined,
					userNumber: exporfilter.userNumber !== '' ? exporfilter.userNumber : undefined,
					registerUserName: exporfilter.registerUserName !== '' ? exporfilter.registerUserName : undefined,
					registerUserNumber: exporfilter.registerUserNumber !== '' ? exporfilter.registerUserNumber : undefined,
					userGroupId: exporfilter.userGroupId !== '' ? exporfilter.userGroupId : undefined,
					userDepartmentId: exporfilter.userDepartmentId !== '' ? exporfilter.userDepartmentId : undefined,
					checkinTime: exporfilter.checkinStartTime !== '' ? [moment(exporfilter.checkinStartTime), moment(exporfilter.checkinEndTime)] : undefined,
					receiveTime: exporfilter.receiveStartTime !== '' ? [moment(exporfilter.receiveStartTime), moment(exporfilter.receiveEndTime)] : undefined
				})
			}, 1000)
			getEmployeeList()
		}
		apiOperateTimer = true
		onCommon()
		// api.getPageAuth().then(list => setAuthList(list))
	}, [])

	//获取列表
	const getAssetPhoneNumberOwnerList = () => {
		if (apiOperateTimer) {
			setLoading(true)
			let params = {
				limit: pageSize,
				page: currentPage,
				companyId: filter.companyId
			}
			api.getAssetPhoneNumberOwnerList(params).then(data => {
				setList(data.list)
				setCount(data.count)
				setLoading(false)
			}).catch(() => setLoading(false))
			apiOperateTimer = false
			let timer = setTimeout(() => {
				apiOperateTimer = true
				clearTimeout(timer)
			}, 1000)
		} else {
			return message.info('请不要频繁操作')
		}
	}

	//获取公共接口
	const onCommon = () => {
		api.getSelectGroupList({ limit: global.paramsLimit }).then(res => setGroupList(res.list)).catch(err => console.log(err))//获取事业群
		Department()//部门
	}

	//获取部门
	const Department = (val) => {
		api.getSelectDepartmentList({
			limit: global.paramsLimit,
			groupId: val
		}).then(res => {
			setDepartmentList(res.list)
		}).catch(err => console.log(err))
	}

	//筛选时调接口
	const getEmployeeList = () => {
		setLoading(true)
		api.getAssetPhoneNumberOwnerList({
			limit: pageSize,
			page: currentPage,
			companyId: filter.companyId,
			number: exporfilter.number,
			phone	: exporfilter.phone,
			userName: exporfilter.userName,
			userNumber: exporfilter.userNumber,
			registerUserName: exporfilter.registerUserName,
			registerUserNumber: exporfilter.registerUserNumber,
			userGroupId: exporfilter.userGroupId,
			userDepartmentId: exporfilter.userDepartmentId,
			checkinStartTime: exporfilter.checkinStartTime,
			checkinEndTime: exporfilter.checkinEndTime,
			receiveStartTime: exporfilter.receiveStartTime,
			receiveEndTime: exporfilter.receiveEndTime
		}).then(data => {
			setList(data.list)
			setCount(data.count)
			setLoading(false)
		}).catch(() => setLoading(false))
	}

	//点击筛选提交表单
	const formSubmit = (e) => {
		e.preventDefault()
		validateFields((err, vals) => {
			//const values = Object.values(vals)
			//console.log(values)
			if (!err) {
				// console.log(vals)
				currentPage = 1
				history.replace(match.path)
				if (!vals.userGroupId) {
					Department()
				}
				exporfilter.number = vals.number ? vals.number : ''
				exporfilter.phone = vals.phone ? vals.phone : ''
				exporfilter.snNumber = vals.snNumber ? vals.snNumber : ''
				exporfilter.userName = vals.userName ? vals.userName : ''
				exporfilter.userNumber = vals.userNumber ? vals.userNumber : ''
				exporfilter.registerUserName = vals.registerUserName ? vals.registerUserName : ''
				exporfilter.registerUserNumber = vals.registerUserNumber ? vals.registerUserNumber : ''
				exporfilter.userGroupId = vals.userGroupId ? vals.userGroupId : ''
				exporfilter.userDepartmentId = vals.userDepartmentId ? vals.userDepartmentId : ''
				exporfilter.checkinStartTime = vals.checkinTime ? vals.checkinTime[0].format('YYYY-MM-DD') : ''
				exporfilter.checkinEndTime = vals.checkinTime ? vals.checkinTime[1].format('YYYY-MM-DD') : ''
				exporfilter.receiveStartTime = vals.receiveTime ? vals.receiveTime[0].format('YYYY-MM-DD') : ''
				exporfilter.receiveEndTime = vals.receiveTime ? vals.receiveTime[1].format('YYYY-MM-DD') : ''
				getEmployeeList()
			}
		})
	}

	const onReset = (e) => {
		e.preventDefault()
		exporfilter = {
			number: '',
			phone: '',
			userName: '',
			userNumber: '',
			registerUserName: '',
			registerUserNumber: '',
			userGroupId: '',
			userDepartmentId: '',
			checkinStartTime: '',
			checkinEndTime: '',
			receiveStartTime: '',
			receiveEndTime: ''
		}
		resetFields()
		currentPage = 1
		history.replace(match.path)
		Department()
		getEmployeeList()
	}

	//筛选
	const onCorpFilter = (data) => {
		filter.companyId = data.map(item => item.id).join(',')
		history.replace(match.path)
		currentPage = 1
		getAssetPhoneNumberOwnerList()
	}

	const onChangeTable = (pagination) => {
		currentPage = pagination.current
		pageSize = pagination.pageSize
		history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
		getEmployeeList()
	}

	//选择事业群
	const onSelectGroup = (data) => {
		Department(data)
	}

	//展开收起
	const toggle = () => {
    setExpand(!expand)
    setAlterationUnfoldPack(!alterationUnfoldPack)
    setAlterationTextUnfoldPack(!alterationTextUnfoldPack)
  }

	const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

	return (
		<>
			<CorpFilter onChange={onCorpFilter} />
			<div className="contract-warp">
				<h4 className="title">其它选项：</h4>
				<span className="contract-btn-a" onClick={toggle}>
					{alterationTextUnfoldPack ? '展开' : '收起'}<Icon type={expand ? 'up' : 'down'} />
				</span>
				<Form onSubmit={formSubmit} {...formItemLayout} className="ant-advanced-search-formTwo">
					<Row gutter={24}>
						<Col span={6}>
							<FormItem label="资产编号">
								{getFieldDecorator('number')(<Input allowClear={true} placeholder="请输入资产编号" />)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="手机号">
								{getFieldDecorator('phone')(<Input allowClear={true} placeholder="请输入技术编号" />)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="使用人">
								{getFieldDecorator('userName')(<Input allowClear={true} placeholder="请输入现使用人" />)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="使用人员工编号">
								{getFieldDecorator('userNumber')(<Input allowClear={true} placeholder="请输入使用人员工编号" />)}
							</FormItem>
						</Col>
					</Row>
					<Row gutter={24} className={alterationUnfoldPack ? "contract-unfold" : ""}>
						<Col span={6}>
							<FormItem label="注册人">
								{getFieldDecorator('registerUserName')(<Input allowClear={true} placeholder="请输入现使用人" />)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="注册人员工编号">
								{getFieldDecorator('registerUserNumber')(<Input allowClear={true} placeholder="请输入使用人员工编号" />)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="使用人所在事业群">
								{getFieldDecorator('userGroupId')(
									<Select
										placeholder="全部"
										allowClear={true}
										dropdownMatchSelectWidth={false}
										showSearch={true}
										optionFilterProp="children"
										onSelect={onSelectGroup}
									>
										{
											groupList.map(v => {
												return <Option key={v.id}>{v.name}</Option>
											})
										}
									</Select>)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="使用人所在部门">
								{getFieldDecorator('userDepartmentId')(
									<Select
										placeholder="全部"
										allowClear={true}
										dropdownMatchSelectWidth={false}
										showSearch={true}
										optionFilterProp="children"
									>
										{
											departmentList.map(v => {
												return <Option key={v.id}>{v.name}（{v.companyName}）</Option>
											})
										}
									</Select>)}
							</FormItem>
						</Col>
					</Row>
					<Row gutter={24} className={alterationUnfoldPack ? "contract-unfold" : ""}>
						<Col span={6}>
							<FormItem label="入库时间">
								{getFieldDecorator('checkinTime')(<RangePicker allowClear={false} />)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="领用时间">
								{getFieldDecorator('receiveTime')(<RangePicker allowClear={false} />)}
							</FormItem>
						</Col>
					</Row>
					<div className="contract-filter" >
						<BtnGroup cancelName="重置" confirmName="查询" onCancel={onReset} />
					</div>
				</Form>
			</div>
			<div className="line"></div>
			<Table
				size="small"
				dataSource={list}
				rowKey="id"
				loading={loading}
				pagination={{
					pageSize,
					total: count,
					current: currentPage,
					showQuickJumper: true,
					showSizeChanger: true,
					pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
				}}
				// scroll={{ x: 2200 }}
				onChange={onChangeTable}
			>
				<Column title="资产编号" dataIndex="number" />
				<Column title="手机号" dataIndex="phone" />
				<Column title="品牌" dataIndex="brandName" />
				<Column title="公司主体" dataIndex="companyName" />
				<Column title="现使用人" dataIndex="userName" />
				<Column title="使用人员工编号" dataIndex="userNumber" />
				<Column title="注册人" dataIndex="registerUserName" />
				<Column title="注册人员工编号" key='registerUserNumber' render={(text, record) => (
					<div>{record.registerUserNumber == 0 ? '' : record.registerUserNumber}</div>
				)}/>
				<Column title="使用人所在部门" dataIndex="userDepartmentName" /> 
				<Column title="使用人所在事业群" dataIndex="userGroupName" />
				<Column title="入库时间" dataIndex="checkinTime" />
				<Column title="领取时间" dataIndex="receiveTime" />
			</Table>
		</>
	)
}

export default Form.create()(MobileMy)