/**
 * 模块名称: 招聘模块 -> 招聘申请
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Form,
  Input,
  InputNumber,
  Select,
  message,
  DatePicker
} from 'antd'
import BtnGroup from '@/components/BtnGroup'
import moment from 'moment'

const FormItem = Form.Item
const Option = Select.Option

const jobType = [
  { id: 1, name: '销售'},
  { id: 2, name: '运营'},
  { id: 3, name: '职能'},
  { id: 4, name: '直播'}
]

const Addrecruit = (props) => {
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form
  const { seteditVisible, getList, setformLoading, addType, editId } = props
  const [saveLoading, setSaveLoading] = useState(false)
  const [selectData, setSelectData] = useState({
    emergency: [], //紧急程度
    reason: [], //增补原因
    sexVal: [], //性别
    marriage: [], //婚姻状况
    education: [], //学历
    isRequest: false
  })
  const [postSalaryEnd, setPostSalaryEnd] = useState('')
  const [substitution, setSubstitution] = useState('')

  useEffect(() => {
    onEdit()
  }, [])

  const getSelectData = () => {
    setformLoading(true)
    return Promise.all([
      // api.getCommon({ type: 'recruit_emergency_level' }),
      api.getCommon({ type: 'recruit_reason' }),
      // api.getCommon({ type: 'sex' }),
      // api.getCommon({ type: 'staff_marriage_status' }),
      api.getCommon({ type: 'staff_education_level' }),
    ]).then(list => {
      // console.log(list)
      setformLoading(false)
      setSelectData({
        // emergency: list[0],
        reason: list[0],
        // sexVal: list[2],
        // marriage: list[3],
        education: list[1],
        isRequest: true
      })
      return list
    }).catch(() => setformLoading(false))
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }

  // 编辑框
  const onEdit = () => {
    if (addType == 0) {//eslint-disable-line
      getSelectData()
    }
    if (addType == 1) {//eslint-disable-line
      api.getStaffRecruitmentInfo({ id: editId }).then(res => {
        // console.log(res)
        let treatment = []
        if (res.treatment) treatment = res.treatment.split('~')
        // console.log(treatment)
        getSelectData().then(() => {
          setPostSalaryEnd(treatment[1])
          setFieldsValue({
            name: res.name,
            // jobStationName: res.jobStationName,
            applyRecruitNum: res.applyRecruitNum,
            treatment: res.treatment ? treatment[0] : '',
            expYear: res.expYear,
            jobType: res.jobType,
            // emergencyLevel: res.emergencyLevel,
            recruitReason: res.recruitReason,
            arrivalDate: res.arrivalDate ? moment(res.arrivalDate) : null,
            jobRequirements: res.jobRequirements ? res.jobRequirements : '',
            // sex: res.sex,
            age: res.age ? res.age : '',
            // marriageStatus: res.marriageStatusCn ? res.marriageStatus : '',
            educationLevel: res.educationLevel ? res.educationLevel : '',
            major: res.major ? res.major : '',
            elseRequirements: res.elseRequirements ? res.elseRequirements : '',
            jobReq: res.jobReq ? res.jobReq : ''
          })
        })
      })
    }
  }

  // 保存招聘
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        // console.log(vals)
        if (editId) {
          vals.id = editId
        }
        setSaveLoading(true)
        api.saveStaffRecruitmentInfo({
          id: vals.id,
          leaveStaffName: vals.leaveStaffName,
          leaveDate: vals.leaveDate ? moment(vals.leaveDate).format('YYYY-MM-DD') : '',
          jobType: vals.jobType,
          jobReq: vals.jobReq,
          name: vals.name,
          // jobStationName: vals.jobStationName,
          applyRecruitNum: vals.applyRecruitNum,
          treatment: vals.treatment ?  vals.treatment + '~' + postSalaryEnd : '',
          expYear: vals.expYear,
          // emergencyLevel: vals.emergencyLevel,
          recruitReason: vals.recruitReason,
          arrivalDate: vals.arrivalDate ? moment(vals.arrivalDate).format('YYYY-MM-DD') : '',
          jobRequirements: vals.jobRequirements,
          // sex: vals.sex,
          age: vals.age,
          // marriageStatus: vals.marriageStatus,
          educationLevel: vals.educationLevel,
          major: vals.major,
          elseRequirements: vals.elseRequirements
        })
          .then(() => {
            setSaveLoading(false)
            seteditVisible(false)
            message.success('添加成功')
            getList()
          })
          .catch(() => setSaveLoading(false))
      }
    })
  }

  //岗位薪资后面部分
  const onPostSalary = (e) => {
    // console.log(e.target.value)
    setPostSalaryEnd(e.target.value)
  }

  //选中增补原因
  const onSupplement = (key, value) => {
    // console.log(key, value.props.children)
    setSubstitution(value.props.children)
  }

  //日期限制
  const disabledDate = (current) => {
    // console.log('current', current)
    return current && current < moment().subtract(1, "day")
  }

  return (
    <div>
      <Form onSubmit={formSubmit} {...formItemLayout}>
        <FormItem label="增补原因">
          {getFieldDecorator('recruitReason', {
            rules: [{ required: true, message: '请选择增补原因' }]
          })(
            <Select placeholder="请选择" onSelect={onSupplement}>
              {selectData.reason.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            </Select>
          )}
        </FormItem>
        {
          substitution == '离职替补' && <FormItem label="替补人员">
            {getFieldDecorator('leaveStaffName', {
              rules: [{ required: true, message: '请输入替补人员' }]
            })(
              <Input placeholder="请输入" />
            )}
          </FormItem>
        }
        {
          substitution == '离职替补' && <FormItem label="替补人员离职日期">
            {getFieldDecorator('leaveDate', {
              rules: [{ required: true, message: '请选择替补人员离职日期' }]
            })(
              <DatePicker placeholder="请选择替补人员离职日期" style={{ width: '100%'}}/>
            )}
          </FormItem>
        }
        <FormItem label="岗位">
          {getFieldDecorator('name', {
            rules: [{ required: true, message: '请输入岗位名称' }]
          })(
            <Input placeholder="请输入岗位名称" />
          )}
        </FormItem>
        <FormItem label="岗位类别">
          {getFieldDecorator('jobType', {
            rules: [{ required: true, message: '请选择岗位类别' }]
          })(
            <Select placeholder="请选择">
              {jobType.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem label="招聘人数">
          {getFieldDecorator('applyRecruitNum', {
            rules: [{ required: true, message: '请输入招聘人数' }]
          })(
            <InputNumber min={1} placeholder="请输入招聘人数" style={{ width: '100%'}}/>
          )}
        </FormItem>
        <FormItem label="岗位薪资">
          {getFieldDecorator('treatment')(
            <Input placeholder="请输入" style={{ width: 179 }}/>
          )}
          <span style={{ marginLeft: '15px', marginRight: '15px' }}>~</span>
          <Input placeholder="请输入" style={{ width: 180 }} onChange={onPostSalary} value={postSalaryEnd}/>
        </FormItem>
        <FormItem label="工作经验">
          {getFieldDecorator('expYear', {
            rules: [{ required: true, message: '请输入' }]
          })(
            <Input placeholder="请输入" />
          )}
        </FormItem>
        <FormItem label="希望到岗日期">
          {getFieldDecorator('arrivalDate')(
            <DatePicker placeholder="请选择希望到岗日期" disabledDate={disabledDate} style={{ width: '100%'}}/>
          )}
        </FormItem>
        <FormItem label="年龄">
          {getFieldDecorator('age')(
            <Input placeholder="请输入年龄" />
          )}
        </FormItem>
        <FormItem label="学历">
          {getFieldDecorator('educationLevel')(
            <Select placeholder="请选择学历">
              {selectData.education.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem label="专业">
          {getFieldDecorator('major')(
            <Input placeholder="请输入专业" />
          )}
        </FormItem>
        <FormItem label="岗位职责">
          {getFieldDecorator('jobRequirements')(
            <Input.TextArea placeholder="请输入岗位职责" />
          )}
        </FormItem>
        <FormItem label="任职资格">
          {getFieldDecorator('jobReq')(
            <Input.TextArea placeholder="请输入任职资格" />
          )}
        </FormItem>

        {/* <FormItem label="职务">
          {getFieldDecorator('jobStationName', {
            rules: [{ required: true, message: '请输入职务' }]
          })(
            <Input placeholder="请输入职务" />
          )}
        </FormItem>
        <FormItem label="紧急程度">
          {getFieldDecorator('emergencyLevel', {
            rules: [{ required: true, message: '请选择紧急程度' }]
          })(
            <Select placeholder="请选择紧急程度">
              {selectData.emergency.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem label="性别">
          {getFieldDecorator('sex', {
            rules: [{ required: true, message: '请选择性别' }]
          })(
            <Select placeholder="请选择性别">
              {selectData.sexVal.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem label="婚姻状况">
          {getFieldDecorator('marriageStatus')(
            <Select placeholder="请选择婚姻状况">
              {selectData.marriage.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            </Select>
          )}
        </FormItem> */}
        <BtnGroup onCancel={() => seteditVisible(false)} loading={saveLoading} />
      </Form>
    </div>
  );
}

export default Form.create()(Addrecruit)

