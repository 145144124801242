/**
 * 模块名称: 招聘管理详情页
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect } from 'react'
import {
  Descriptions,
  Steps,
  Button,
  Modal,
  Input,
  InputNumber,
  Table,
  Icon,
  message,
  Select,
  Spin
} from 'antd'
import api from '@/api'
import { parseSearch, debounce } from '@/utils'
import Auth from '@/components/AuthMiddleware'
import CryptoJS from 'crypto-js'

const Option = Select.Option
const dItem = Descriptions.item
const { Step } = Steps;
const { Column } = Table

const RecruitDetail = (props) => {
  const { location, history } = props
  const [detailLoading, setDetailLoading] = useState(true)
  const [detailData, setDetailData] = useState({})
  const [oldDetailData, setOldDetailData] = useState({}) //原来数据
  const [detailDataId, setdetailDataId] = useState('')

  const [authList, setAuthList] = useState([])//招聘分配权限按钮是否展示
  const [visibleAllocation, setVisibleAllocation] = useState(false)
  const [distStaffKey, setDistStaffKey] = useState('')
  const [distStaffList, setDistStaffList] = useState([])

  const [statusList, setStatusList] = useState([])
  const [logConList, setLogConList] = useState([])
  const [buttonAuthList, setButtonAuthList] = useState([])
  const [passModalVisible, setModalVisible] = useState(false) //确认弹框
  const [turnModalVisible, setTurnModalVisible] = useState(false) //驳回弹框
  const [textAreaVal, setTextAreaVal] = useState('') //判断框是否为空
  const [textDisplay, setTextDisplay] = useState(true) //是否提示必选
  const [pasDesc, setPasDesc] = useState('')  //通过意见
  const [jobReq, setJobReq] = useState([])
  const [jobRequirements, setJobRequirements] = useState([])
  const search = parseSearch(location.search)

  const [feedBackNum, setFeedBackNum] = useState('')
  const [feedBackTxt, setFeedBackTxt] = useState('')


  useEffect(() => {
    let plaintext = search.sign
    let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()

    if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
      api.getStaffRecruitmentInfo({ id: search.id }).then(data => {
        // console.log(data)
        if (search.msgId) {
          api.setMessageReaded({ ids: [search.msgId] })
        }
        setDetailData(data)
        let jobReq
        let jobRequirements
        if(data.jobReq) {
          jobReq = data.jobReq.split('\n')
        }
        if(data.jobRequirements) {
          jobRequirements = data.jobRequirements.split('\n')
        }
        setJobReq(jobReq)
        setJobRequirements(jobRequirements)
        setOldDetailData(data.oldData ? data.oldData : data) //更新前数据
        setdetailDataId(data.id)
        setStatusList(data.procedureStatusList) // 审批记录
        setLogConList(data.logList) // 审批日志
        setButtonAuthList(data.buttonAuthList) // 审批权限
        setDetailLoading(false)
      })
      api.getPageAuth().then(list => setAuthList(list))
    } else {
      message.error('没有本页访问权限')
      setTimeout(() => {
        history.go(-2)
      }, 1000)
    }
  }, [])

  //获取页面信息
  const getStaffRecruitmentInfo = () => {
    api.getStaffRecruitmentInfo({ id: search.id }, true).then(data => {
      setDetailData(data)
      setOldDetailData(data.oldData ? data.oldData : data) //更新前数据
      setdetailDataId(data.id)
      setStatusList(data.procedureStatusList) // 审批记录
      setLogConList(data.logList) // 审批日志
      setButtonAuthList(data.buttonAuthList) // 审批权限
    }) 
  }

  // 通过
  const onBtnPassShow = () => {
    if(detailData.needFeedBack && feedBackNum === '')
    {
      message.info('请输入招聘人数')
      return
    }
    setModalVisible(true)
  }

  const onPassCancel = () => {
    setModalVisible(false)
  }

  const onPassDescChange = (e) => {
    setPasDesc(e.target.value)
  }

  const onApprove = () => {
    api.staffRecruitmentPass({
      id: detailDataId,
      remark: pasDesc,
      finalRecruitNum: feedBackNum,
      feedbackInfo: feedBackTxt
    }).then(data => {
      getStaffRecruitmentInfo() //确认通过后
    })
    setModalVisible(false)
  }

  // 驳回
  const onBtnTurnShow = () => {
    setTurnModalVisible(true)
    setTextDisplay(true)
  }

  const onTurnCancel = () => {
    setTurnModalVisible(false)
  }

  const onTurnApprove = () => {
    if (buttonAuthList.reject_msg_require) {
      if (textAreaVal.trim() === '') {
        setTextDisplay(false)
        return
      }
    }
    api.staffRecruitmentReject({
      id: detailDataId,
      remark: textAreaVal
    }).then(data => {
      getStaffRecruitmentInfo() //确认驳回后
    })
    setTurnModalVisible(false)
  }

  const onDescChange = (e) => {
    setTextAreaVal(e.target.value)
    setTextDisplay(true)
  }

  // 驳回样式
  const turnNode = () => {
    return <>
      确认驳回 <Icon type="close-circle" theme="twoTone" twoToneColor="#DC143C" />
    </>
  }

  // 同意样式
  const agreeNode = () => {
    return <>
      确认通过 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
    </>
  }

  // 转上级
  const onTurnUp = () => {
    api.staffRecruitmentTurnUp({
      id: detailDataId,
      remark: ''
    }).then(data => {
      history.push('/hr/recruit')
    }).catch()
  }

  // 补充资料
  const onReplenish = () => {
    api.staffRecruitmentReplenish({
      id: detailDataId,
      remark: ''
    }).then(data => {
      history.push('/hr/recruit')
    }).catch()
  }

  //招聘反馈 招聘人数
  const onFeedBackNumChange = data => {
    setFeedBackNum(data)
  }
  //招聘反馈 反馈意见
  const onFeedBackTxtChange = e => {
    e.preventDefault()
    setFeedBackTxt(e.target.value)
  }
  // //提交反馈意见
  // const submitFeedBack = () => {
  //   if(feedBackNum === '')
  //   {
  //     message.info('请输入招聘人数')
  //     return
  //   }
  //   api.staffRecruitmentFeedBack({
  //     id: detailDataId,
  //     finalRecruitNum: feedBackNum,
  //     feedbackInfo: feedBackTxt
  //   }).then(() => {
  //     message.success('操作成功')
  //     getStaffRecruitmentInfo()
  //   }).catch(err => {
  //     //console.log(err)
  //   })
  // }
  // //取消反馈意见
  // const cancelFeedBack = () => {
  //   history.push('/hr/recruit')
  // }
  //招聘人数反馈node
  const feedBackNumNode = () => {
    return <>
    <span style={{color: 'red', fontSize: '20px', verticalAlign: '-5px'}}>* </span> 
    <span>最终招聘人数</span>
    </>
  }
  //招聘人数反馈文本框node
  const feedBackTxtNode = () => {
    return <span style={{marginLeft: '42px'}}>招聘反馈</span>
  }

  //分配招聘权限
  const onAllocationRole = () => {
    setVisibleAllocation(true)
    if (distStaffKey != '') setDistStaffKey('')
    api.getStaffRecruitmentDistStaffList().then(res => {
      console.log(res)
      setDistStaffList(res)
    })
  }

  //分配招聘人员确定弹窗
  const batchHandleOk = () => {
    // console.log('distStaffKey', distStaffKey)
    if (distStaffKey == '') {
      return message.warning('请选择分配人')
    }
    api.saveStaffRecruitmentDistribution({ ids: detailDataId, distStaff: distStaffKey})
    .then(res => {
      message.success('操作成功') 
      setVisibleAllocation(false)
      getStaffRecruitmentInfo()
    })
    .catch(err => console.log(err))
  }

  //分配招聘人员取消弹窗
  const batchHandleCancel = () => {
    setVisibleAllocation(false)
  }

  //分配招聘人员弹窗内列表
  const onDistStaff = (key) => {
    setDistStaffKey(key)
  }

  //退回列表页
  const onGoBack = () => {
    history.push({pathname:'/hr/recruit',state: { goBack: 1}})
  }

  return (
    <Spin spinning={detailLoading}>
      <div className="pro-detail">
        {
          search.new && search.new == 1 && <Button onClick={onGoBack} type="primary" style={{
            position: 'absolute',
            right: '20px',
            top: '-20px'
          }}>返回上一页</Button>
        }
        <Descriptions title="招聘申请" layout="horizontal " className="pro-detail-title">
          <dItem label="申请编号">{detailData.bizno}</dItem>
        </Descriptions>
        <Descriptions title="招聘申请流程" className="pro-detail-smailtitle"></Descriptions>
        <div className="promotionProcess" >
          <Steps>
            {
              statusList.map((item, index) => {
                let status = 'wait'
                if (item.time && item.time.length > 1) {
                  status = 'process'
                }
                return <Step status={status}
                  key={index}
                  value={item.time}
                  title={item.nodeName}
                  description={
                    <>
                      {item.persons && <div title={`${item.persons.map((item) => item)}`}>{`${item.persons.map((item) => item)}`}</div>}
                      <div title={item.statusName}>{item.statusName}</div>
                      <div title={item.time}>{item.time}</div>
                    </>
                  }
                // {`${item.persons.map((item) => item)} ${item.statusName} ${item.time}`} 
                />
              })
            }
          </Steps>
        </div>
        <div className="line-box"></div>
        <Descriptions title="申请信息"
          layout="vertical"
          className="pro-detail-smailtitle pro-apply"
        >
          <dItem label="申请部门">
            <>
              {detailData.departmentName}
              {oldDetailData.departmentName !== detailData.departmentName ? <div className="change-text">变更前：{oldDetailData.departmentName}</div> : null}
            </>
          </dItem>

          <dItem label="申请人">
            <>
              {detailData.applyStaffName}
              {oldDetailData.applyStaffName !== detailData.applyStaffName ? <div className="change-text">变更前：{oldDetailData.applyStaffName}</div> : null}
            </>
          </dItem>
          <dItem label="申请时间">
            <>
              {detailData.addTime}
              {oldDetailData.addTime !== detailData.addTime ? <div className="change-text">变更前：{oldDetailData.addTime}</div> : null}
            </>
          </dItem>
          <dItem label="岗位">
            <>
              {detailData.name}
              {oldDetailData.name !== detailData.name ? <div className="change-text">变更前：{oldDetailData.name}</div> : null}
            </>
          </dItem>
          <dItem label="岗位类型">
            <>
              {detailData.jobTypeCn}
              {oldDetailData.jobTypeCn !== detailData.jobTypeCn ? <div className="change-text">变更前：{oldDetailData.jobTypeCn}</div> : null}
            </>
          </dItem>
          <dItem label="招聘人数">
            <>
              {detailData.applyRecruitNum}
              {oldDetailData.applyRecruitNum !== detailData.applyRecruitNum ? <div className="change-text">变更前：{oldDetailData.applyRecruitNum}</div> : null}
            </>
          </dItem>
          <dItem label="岗位薪资">
            <>
              {detailData.treatment ? detailData.treatment : '--'}
              {oldDetailData.treatment !== detailData.treatment ? <div className="change-text">变更前：{oldDetailData.treatment}</div> : null}
            </>
          </dItem>
          <dItem label="增补原因">
            <>
              {detailData.recruitReasonCn}
              {oldDetailData.recruitReasonCn !== detailData.recruitReasonCn ? <div className="change-text">变更前：{oldDetailData.recruitReasonCn}</div> : null}
            </>
          </dItem>
          {
            detailData.recruitReasonCn == '离职替补' && <dItem label="替补人员">
              <>
                {detailData.leaveStaffName ? detailData.leaveStaffName : '无'}
                {oldDetailData.leaveStaffName !== detailData.leaveStaffName ? <div className="change-text">变更前：{oldDetailData.leaveStaffName}</div> : null}
              </>
            </dItem>
          }
          {
            detailData.recruitReasonCn == '离职替补' && <dItem label="替补人员离职日期">
              <>
                {detailData.leaveDate ? detailData.leaveDate.slice(0, 10) : '无'}
                {oldDetailData.leaveDate !== detailData.leaveDate ? <div className="change-text">变更前：{oldDetailData.recruitReasonCn == '新增' ? '--' : oldDetailData.leaveDate}</div> : null}
              </>
            </dItem>
          }
          <dItem label="期望到岗日期">
            <>
              {detailData.arrivalDate ? detailData.arrivalDate : '--'}
              {oldDetailData.arrivalDate !== detailData.arrivalDate ? <div className="change-text">变更前：{oldDetailData.arrivalDate}</div> : null}
            </>
          </dItem>
          <dItem label="年龄">
            <>
              {detailData.age == 0 ? '不限' : detailData.age}
              {oldDetailData.age !== detailData.age ? <div className="change-text">变更前：{oldDetailData.age}</div> : null}
            </>
          </dItem>
          <dItem label="工作经验">
            <>
              {detailData.expYear ? detailData.expYear : '--'}
              {oldDetailData.expYear !== detailData.expYear ? <div className="change-text">变更前：{oldDetailData.expYear}</div> : null}
            </>
          </dItem>
          <dItem label="学历">
            <>
              {detailData.educationLevelCn ? detailData.educationLevelCn : '--'}
              {oldDetailData.educationLevelCn !== detailData.educationLevelCn ? <div className="change-text">变更前：{oldDetailData.educationLevelCn}</div> : null}
            </>
          </dItem>
          <dItem label="专业" span={detailData.recruitReasonCn == '新增' ? 3 : 1}>
            <>
              {detailData.major ? detailData.major : '--'}
              {oldDetailData.major !== detailData.major ? <div className="change-text">变更前：{oldDetailData.major}</div> : null}
            </>
          </dItem>
          <dItem label="岗位要求" span={3}>
            <>
              {detailData.jobRequirements ? jobRequirements.map(((item, index) => <div key={index}>{item}</div>)) : '无'}
              {oldDetailData.jobRequirements !== detailData.jobRequirements ? <div className="change-text">变更前：{oldDetailData.jobRequirements}</div> : null}
            </>
          </dItem>
          <dItem label="任职资格" span={3}>
            <>
              {detailData.jobReq ? jobReq.map(((item, index) => <div key={index}>{item}</div>))  : '无'}
              {oldDetailData.jobReq !== detailData.jobReq ? <div className="change-text">变更前：{oldDetailData.jobReq}</div> : null}
            </>
          </dItem>
        </Descriptions>

        <div className="line-box"></div>
        {detailData.needFeedBack === false && detailData.finalRecruitNum >= 0 && <><Descriptions title="招聘办理情况"
          layout="vertical"
          className="pro-detail-smailtitle pro-apply"
        >
          <dItem label="最终招聘人数">
            <>
              {detailData.finalRecruitNum}
            </>
          </dItem>

          <dItem label="招聘反馈信息">
            <>
              {detailData.feedbackInfo ? detailData.feedbackInfo : '无'}
            </>
          </dItem>
        </Descriptions>
        <div className="line-box"></div></>}
        <Descriptions
          title="审批记录"
          layout="horizontal"
          className="pro-detail-smailtitle"
        >
        </Descriptions>
        <Table
          dataSource={logConList}
          rowKey="id"
          pagination={false}
        >
          <Column title="姓名" dataIndex="userInfo.staffName" />
          <Column title="时间" dataIndex="addTime" />
          <Column title="状态" dataIndex="status" />
          <Column title="审批意见" dataIndex="chargeMsg" />
        </Table>
        {detailData.needFeedBack === true && <Descriptions
          title="招聘反馈"
          layout="horizontal"
          className="pro-detail-smailtitle"
          style={{marginTop: '30px'}}
          column={1}
        >
          <dItem label={feedBackNumNode()}>
            <InputNumber placeholder="请输入最终招聘人数" onChange={onFeedBackNumChange} step={1} min={0} style={{width: '300px'}}/>
          </dItem>
          <dItem label={feedBackTxtNode()}>
            <Input.TextArea placeholder="请输入反馈意见" rows={4} onChange={onFeedBackTxtChange} style={{width: '300px'}} />
          </dItem>
          {/* <dItem>
            <div style={{marginTop: '20px'}}>
              <Button type="primary" onClick={submitFeedBack} style={{marginRight: '20px'}}>提交</Button>
              <Button onClick={cancelFeedBack}>取消</Button>
            </div> 
          </dItem> */}
        </Descriptions>}

        <div className="btn-setting">
          {
            detailData.needDist && <Auth auths={authList} code="staff-attendance-distribution">
              <Button icon="solution" onClick={onAllocationRole} style={{ marginRight: '15px'}}>分配招聘人员</Button>
            </Auth>
          }
          <Button hidden={!buttonAuthList.replenish} onClick={debounce(() => {onReplenish()}, 3000)}>补充资料</Button>
          <Button hidden={!buttonAuthList.return_up} onClick={debounce(() => {onTurnUp()}, 3000)}>转上级</Button>
          <Button hidden={!buttonAuthList.hasChargerPower} onClick={onBtnTurnShow}>驳回</Button>
          <Modal
            // title="确认驳回"
            title={turnNode()}
            visible={turnModalVisible}
            onOk={debounce(() => {onTurnApprove()}, 3000)}
            onCancel={onTurnCancel}
            footer={[
              <Button key="back" onClick={onTurnCancel}>取消</Button>,
              <Button key="submit" type="primary" onClick={debounce(() => {onTurnApprove()}, 3000)}>确定</Button>
            ]}
          >
            <div>
              <span>审批意见:</span>
              <Input.TextArea placeholder="请输入驳回审批意见" value={textAreaVal} onChange={onDescChange} maxLength={100} />
            </div>
            <span className="poine-text" hidden={textDisplay}>请填写审批意见</span>
          </Modal>
          <Button hidden={!buttonAuthList.hasChargerPower} type="primary" onClick={onBtnPassShow}>通过</Button>
          <Modal
            // title="确认通过"
            title={agreeNode()}
            visible={passModalVisible}
            onOk={debounce(() => {onApprove()}, 3000)}
            onCancel={onPassCancel}
            footer={[
              <Button key="back" onClick={onPassCancel}>取消</Button>,
              <Button key="submit" type="primary" onClick={debounce(() => {onApprove()}, 3000)}>确定</Button>
            ]}
          >
            <div>
              <span>审批意见:</span>
              <Input.TextArea placeholder="请输入通过审批意见" value={pasDesc} onChange={onPassDescChange} maxLength={100} />
            </div>
          </Modal>
          <Modal
              title='分配招聘人员'
              visible={visibleAllocation}
              onOk={debounce(() => {batchHandleOk()}, 3000)}
              onCancel={batchHandleCancel}
              footer={[
                <Button key="back" onClick={batchHandleCancel}>取消</Button>,
                <Button key="submit" type="primary" onClick={debounce(() => {batchHandleOk()}, 3000)}>确定</Button>
              ]}
          >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <div>员工姓名：</div>
              <Select 
                style={{ width: 180 }} 
                placeholder="请选择" 
                onSelect={onDistStaff} 
                showSearch={true} 
                optionFilterProp="children" 
                value={distStaffKey}
              >
                {
                  distStaffList.map((v, i) => {
                    return <Option value={v.id} key={v.id}>{v.name.staffName}</Option>
                  })
                }
              </Select>
            </div>
          </Modal>
        </div>
      </div>
    </Spin>
  )
}

export default RecruitDetail;
