/**
 * 模块名称: 组织架构
 * @author lids@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Structure from './Structure'

const StructureIndex = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/detail'} component={Structure} />
      <Route component={Structure} />
    </Switch>
  )
}

export default StructureIndex