/**
 * 模块名称: 人力资源管理模块
 * @author liujingxue@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Employee from './Employee'
import Promotion from './Promotion'
import JobStationLevel from './JobStationLevel'
import JobTransfer from './JobTransfer'
import Recruit from './Recruit'
import Dimission from './Dimission'
import Structure from './Structure'
import Premployee from './Premployee'
import './assets/style.scss'

const ManpowerIndex = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/employee'} component={Employee} />
      <Route path={match.path + '/promotion'} component={Promotion} />
      <Route path={match.path + '/jobStationLevel'} component={JobStationLevel} />
      <Route path={match.path + '/jobTransfer'} component={JobTransfer} />
      <Route path={match.path + '/recruit'} component={Recruit} />
      <Route path={match.path + '/dimission'} component={Dimission} />
      <Route path={match.path + '/Structure'} component={Structure} />
      <Route path={match.path + '/premployee'} component={Premployee} />
      <Route component={Employee} />
    </Switch>
  )
}

export default ManpowerIndex