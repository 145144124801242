/**
 * 模块名称: 转岗管理
 * @author zhuyan@372163.com
 */
import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Drawer,
  Form,
  Alert,
  Button,
  Input,
  Select,
  message,
  Icon,
  Popconfirm,
  Spin,
  Upload,
  Tooltip
} from 'antd'
import BtnGroup from '@/components/BtnGroup'
import CorpFilter from '@/components/CorpFilter'
import Auth from '@/components/AuthMiddleware'
import { parseSearch } from '@/utils'
import { upLoadModule } from '@/utils/common'
import { Link } from 'react-router-dom'
import CryptoJS from 'crypto-js'

const { Dragger } = Upload;
const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
let editId = '' //编辑使用
let pageSize = 10
let currentPage = 1
let companyId = 0
let departmentId = 0

let boardData = {
  staffNo: '',
  staffName: '',
  companyId: '',
  groupId: '',
  departmentId: '',
  approvalStatus: '',
  addTimeMin: '',
  addTimeMax: ''
}

const JobTransfer = (props) => {
  const { match, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [visible, setVisible] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [formLoading, setformLoading] = useState(false)
  const [fileList, setFileList] = useState([])
  const [approvalStatusAll, setApprovalStatusAll] = useState([])
  const [departments, setDepartments] = useState([])
  const [statusVal, setStatusVal] = useState(undefined)
  const [departmentSel, setdepartmentSel] = useState(undefined)
  const [companyData, setCompanyData] = useState('')
  const [proSection, setProSection] = useState([]) //职级
  const [proSectionFlag, setProSectionFlag] = useState(true)
  const [authList, setAuthList] = useState([])
  const draggerRef = useRef(null)
  const [selectData, setSelectData] = useState({
    companyName: [], //姓名
    proCompany: [], //公司
    proSection: [],  //部门
    proPosition: [], //职级
    proLevel: [], //级别
    leaderList: [], //主管
    isRequest: false
  })
  const search = parseSearch(props.location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10

    boardData = {
      staffNo: '',
      staffName: '',
      companyId: '',
      groupId: '',
      departmentId: '',
      approvalStatus: '',
      addTimeMin: '',
      addTimeMax: ''
    }
    getStaffTransferList()
    onsetApprovalStatus()
    onsetDepartmentSel()
    // getSelectData()  //测试
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const getStaffTransferList = () => {
    setLoading(true)
    api.getStaffTransferList({
      type: 2,
      staffNo: boardData.staffNo,
      staffName: boardData.staffName,
      companyId: boardData.companyId,
      groupId: boardData.groupId,
      departmentId: boardData.departmentId,
      approvalStatus: boardData.approvalStatus,
      addTimeMin: boardData.addTimeMin,
      addTimeMax: boardData.addTimeMax,
      limit: pageSize,
      page: currentPage
    }).then(data => {
      setList(data.list)
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  const onsetApprovalStatus = () => {
    api.getAuditStatusList({ type: '6'}).then(data => {

      setApprovalStatusAll(data)
    })
    // api.getCommon({ type: 'staff_promotion_approval_status' }).then(data => {
    //   setApprovalStatusAll(data)
    // })
  }

  const onsetDepartmentSel = () => {
    api.getSelectDepartmentList().then(data => {
      setDepartments(data.list)
    })
  }

  const getSelectData = () => {
    setformLoading(true)
    return Promise.all([
      api.getManpower({ limit: global.paramsLimit, isOnJob: 1, isValidAccount: 0, needAuth: 1 }),
      api.getSelectCompanyList({ limit: global.paramsLimit, isValid: 1 }),
      api.getCommon({ type: 'staff_job_position' }),
      api.getSelectJobStationLevelList({ limit: global.paramsLimit }),
      api.getLeaderList({ limit: global.paramsLimit, minJobPosition: 2 })
    ]).then(list => {
      setformLoading(false)
      setSelectData({
        companyName: list[0].list,
        proCompany: list[1].list,
        proPosition: list[2],
        proLevel: list[3].list,
        leaderList: list[4].list,
        isRequest: true
      })
      return list
    }).catch(() => setformLoading(false))
  }

  // 保存申请转岗
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      let uploadList = []
      for (let i = 0, k = fileList.length; i < k; i++) {
        if (fileList[i].status !== 'removed') {
          uploadList.push(fileList[i].url)
        }
      }
      if (!err) {
        if (editId) {
          vals.id = editId
        }
        setSaveLoading(true)
        api.saveStaffTransferInfo({
          id: vals.id,
          staffId: companyData.id,
          newCompanyId: vals.newCompanyId,
          newDepartmentId: vals.newDepartmentId,
          newJobPosition: vals.newJobPosition,
          newJobStation: vals.newJobStation,
          managerStaffId: vals.managerStaffId,
          remark: vals.remark,
          // attachment: JSON.stringify(uploadList)
          attachment: uploadList.join(',')
        })
          .then(() => {
            setSaveLoading(false)
            setVisible(false)
            message.success('添加成功')
            getStaffTransferList()
          })
          .catch(() => setSaveLoading(false))
      }
    })
  }

  // 补充资料(编辑)
  const onEdit = (data) => {
    // console.log("data", data)
    resetFields()
    if (data.attachmentCn) {
      data.attachmentCn.forEach((item, index) => {
        fileList.push({
          status: 'done',
          uid: index,
          name: item.name,
          url: item.url
        })
      })
      setFileList(data.attachmentCn.map((item, i) => ({ status: 'done', uid: i, name: item.name, url: item.url })))
    } else {
      setFileList([])
    }
    setVisible(true)
    editId = data.id
    getSelectData().then(() => {
      setFieldsValue({
        id: data.id,
        companyNameId: data.staffId,
        newCompanyId: data.newCompanyId,
        newDepartmentId: data.newDepartmentId,
        newJobPosition: data.newJobPosition,
        newJobStation: data.newJobStation ? data.newJobStation : '',
        managerStaffId: data.managerStaffId,
        remark: data.remark
      })
      setCompanyData({
        id: data.staffId,
        leaderName: data.staffInfo.leaderName,
        companyName: data.oldCompanyName,
        jobPositionCn: data.oldJobPositionCn,
        jobPosition: data.oldJobPosition,
        staffNo: data.staffInfo.staffNo,
        departmentName: data.oldDepartmentName,
        jobStationCn: data.oldJobStationCn
      })
      companyId = data.newCompanyId
      departmentId = data.newDepartmentId
      api.getSelectDepartmentList({ companyId: data.newCompanyId }).then(data => {
        setProSection(data.list)
        setProSectionFlag(false)
      })
    })
  }

  // 新建转岗
  const onAddCorp = () => {
    editId = ''
    setVisible(true)
    resetFields()
    setProSectionFlag(true)
    setCompanyData('')
    setFileList([])
    if (!selectData.isRequest) {
      getSelectData()
    }
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getStaffTransferList()
  }

  const onChangePromotion = (data) => {
    setDepartments([])
    setdepartmentSel(undefined)
    boardData.departmentId = ''
    boardData.companyId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getStaffTransferList()
  }

  // 选中公司，获取部门
  const onSelectProfile = (id) => {
    setFieldsValue({
      newDepartmentId: undefined
    })
    companyId = id
    api.getSelectDepartmentList({ companyId: id }).then(data => {
      setProSection(data.list)
      setProSectionFlag(false)
    })
  }

  // 选中部门
  const onSelectProfileDepartment = (id) => {
    departmentId = id
  }

  // 选中职级
  const onSelectProfileJobPosition = (id) => {
    
  }

  // 选中岗位级别
  const onSelectProfileJobStation = (id) => {
    
  }

  // 调整后直接主管
  const onSelectProfileManagerStaff = (id) => {
    
  }

  // 筛选公司
  const companyNameSelect = (value) => {
    api.getSelectCompanyList({
      name:value
    }).then(data => {
      setSelectData({
        ...selectData,
        proCompany: data.list,
      })
    }).catch()
  }

  // 筛选部门
  const departmentNameSelect = (value) => {
    api.getSelectDepartmentList({
      companyId:companyId,
      name:value
    }).then(data => {
      setSelectData({
        ...selectData,
        proSection: data.list,
      })
      setProSection(data.list)
      setProSectionFlag(false)
    }).catch()
  }

  // 筛选职级
  const jobPositionNameSelect = (value) => {
    api.getCommon({ type: 'staff_job_position', val: value }).then(data => {
      setSelectData({
        ...selectData,
        proPosition: data,
      })
    }).catch()
  }

  // 筛选岗位级别
  const jobStationNameSelect = (value) => {
    api.getSelectJobStationLevelList({ limit: global.paramsLimit, name: value }).then(data => {
      setSelectData({
        ...selectData,
        proLevel: data.list,
      })
    }).catch()
  }

  // 筛选调整后直接主管
  const managerStaffNameSelect = (value) => {
    api.getLeaderList({ limit: global.paramsLimit, excludeStaffIds: companyData.id ? companyData.id : '', minJobPosition: 2, staffName: value }).then(data => {
      setSelectData({
        ...selectData,
        leaderList: data.list,
      })
    }).catch()
  }

  // 筛选
  const staffNameInp = useRef(null)
  const onSearch = () => {
    boardData.staffName = staffNameInp.current.value
    history.replace(match.path)
    currentPage = 1
    getStaffTransferList()
  }

  // 重置
  const onReset = () => {
    setStatusVal(undefined)
    setdepartmentSel(undefined)
    boardData.approvalStatus = ''
    boardData.departmentId = ''
    boardData.staffName = ''
    staffNameInp.current.value = ''
    currentPage = 1
    history.replace(match.path)
    getStaffTransferList()
  }

  // 删除
  const onDelete = (id) => {
    api.delstaffTransferUpdateIsDel({ id }, true).then(() => {
      message.success('删除成功')
      getStaffTransferList()
    })
  }

  // const onUserinfo = (record) => {
  //   api.getStaffTransferInfo({ id: record.id }).then(data => {
  //     sessionStorage.setItem("key", JSON.stringify(data))
  //     history.push('/hr/jobTransferDetail')
  //   })
  // }

  // 筛选清空下拉
  const onStatusSelect = (e) => {
    setStatusVal(e)
    boardData.approvalStatus = e
  }

  const onDepartmentSel = (e) => {
    setdepartmentSel(e)
    boardData.departmentId = e
  }

  const onDropdownVisible = () => {
    api.getSelectDepartmentList({ limit: global.paramsLimit, companyId: boardData.companyId }).then(data => setDepartments(data.list)) //部门
  }

  // 文件上传 
  const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.gif, .png, .jpg, .PDF, .doc, .docx, .csv, .xlsx, .zip, .rar, .jepg',
		allowSizeType: 2
	})

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }

  const onSelectComp = (e) => {
    selectData.companyName.forEach((item, index, arr) => {
      if (item.id === e) {
        setCompanyData(item)
      }
    })
    setFieldsValue({
      managerStaffId: undefined
    })
  }

  // 筛选姓名
  const nameSelect = (value) => {
    api.getManpower({
      staffName: value,
      isOnJob: 1,
      isValidAccount: 0,
      needAuth: 0,
      limit: global.paramsLimit,
    }).then(data => {
      setSelectData({
        ...selectData,
        companyName: data.list,
      })
    }).catch()
  }

  const onStaffVisible = () => {
    api.getLeaderList({ limit: global.paramsLimit, excludeStaffIds: companyData.id ? companyData.id : '', minJobPosition: 2 }).then(data =>
      setSelectData({
        ...selectData,
        leaderList: data.list,
      }))
  }

  const onKeyup = (e) => {
    if (e.keyCode === 13) {
      onSearch()
    }
  }

  return (
    <>
      <CorpFilter onChange={onChangePromotion} />
      <div className="mobilize-sty">
        <h4 className="bulle-text">其它选项:</h4>
        <div style={{ display:'flex' }}>
          <div className="filter-item">
            <label htmlFor="">姓名：</label>
            <input className="ant-input" ref={staffNameInp} type="title" placeholder="请输入姓名" onKeyUp={onKeyup} />
          </div>
          <div className="filter-item">
            <label htmlFor="">审批状态：</label>
            <Select
              placeholder="全部"
              value={statusVal}
              style={{ width: 200 }}
              onSelect={onStatusSelect}
              dropdownMatchSelectWidth={false}
              showSearch={true}
              optionFilterProp="children"
            >
              {
                approvalStatusAll.map((v, i) => {
                  return <Option value={v.id} key={i}>{v.name}</Option>
                })
              }
            </Select>
          </div>
          <div className="filter-item">
            <label htmlFor="">部门：</label>
            <Select
              placeholder="全部"
              value={departmentSel}
              style={{ width: 200 }}
              onSelect={onDepartmentSel}
              onDropdownVisibleChange={bool => bool && onDropdownVisible()}
              dropdownMatchSelectWidth={false}
              showSearch={true}
              optionFilterProp="children"
            >
              {
                departments.map((v, i) => {
                  return !boardData.companyId.length ? <Option title={v.name + '（' + v.companyName + '）'} key={v.id}>{v.name}（{v.companyName}）</Option> : <Option title={v.name} key={v.id}>{v.name}</Option>
                })
              }
            </Select>
          </div>
          <div className="bulle-wrap-btn">
            <BtnGroup cancelName="重置" confirmName="筛选" onConfirm={onSearch} onCancel={onReset} />
          </div>
        </div>
      </div>
      <div className="line"></div>
      <div className="bulle-wrap-add">
        <Auth auths={authList} code="staff-attendance-apply">
          <Button type="primary" icon="user" onClick={onAddCorp}>申请转岗</Button>
        </Auth>
      </div>
      <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 2000 }}
        onChange={onChangeTable}
      >
        <Column title="姓名"
          dataIndex="staffName"
          fixed="left"
          width={150}
          render={(companyName, record) => <Link to={`${match.path}/detail?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{companyName}</Link>}
        // render={(companyName, record) => (<a onClick={() => onUserinfo(record)} style={{ cursor: 'pointer' }}>{companyName}</a>)}
        />
        <Column title="员工编号" dataIndex="staffInfo.staffNo" />
        <Column title="所属公司" dataIndex="oldCompanyName" />
        <Column title="原部门" dataIndex="oldDepartmentName" />
        <Column title="变更后部门" dataIndex="newDepartmentName" />
        <Column title="原职级" dataIndex="oldJobPositionCn" />
        <Column title="变更后职级" dataIndex="newJobPositionCn" />
        <Column title="原岗位级别" dataIndex="oldJobStationCn" />
        <Column title="变更后岗位级别" dataIndex="newJobStationCn" />
        <Column title="入职时间" dataIndex="staffInfo.entryDate" />
        <Column title="审批状态" dataIndex="approvalStatusCn" />
        <Column title="申请日期" dataIndex="addTime" />
        <Column title="审批通过日期" dataIndex="approvalPassDate" />
        <Column title="操作" key="set" fixed="right" width={100} render={(text, record) => (
          <>
            {
              (record.userAuth && record.userAuth.allowReplenish) || (record.userAuth && record.userAuth.allowRakeUp) ?
                <Tooltip title="编辑" placement="bottom">
                  <Icon className="operate-icon" style={{ color: '#1890ff' }} type="edit" onClick={() => onEdit(record)} />
                </Tooltip>
                : null
            }

            {' '}
            {
              record.userAuth && record.userAuth.allowDelete ?
                <Popconfirm title="确认删除吗？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                  <Tooltip title="删除" placement="bottom">
                    <Icon className="operate-icon" style={{ color: 'red' }} type="delete" />
                  </Tooltip>
                </Popconfirm> : null
            }

          </>
        )} />
      </Table>
      <Drawer
        title="申请转岗"
        width={600}
        onClose={() => setVisible(false)}
        visible={visible}
        destroyOnClose={true}
      >
        <Spin spinning={formLoading}>
          <Form onSubmit={formSubmit} {...formItemLayout}>
            <FormItem label="姓名">
              {getFieldDecorator('companyNameId', {
                rules: [{ required: true, message: '请输入姓名' }]
              })(
                <Select placeholder="请输入姓名"
                  // filterOption={false}
                  onSelect={onSelectComp}
                  // onSearch={nameSelect}  
                  autoFocus
                  showSearch={true}
                  optionFilterProp="children"
                  >
                  {selectData.companyName.map(item => <Option key={item.id} value={item.id}>{item.staffName}</Option>)}
                </Select>
              )}
              <div className="forPromotion">
                <div className="forPromotiontext">
                  <span>原直接主管: {companyData.leaderName}</span>
                  <span>原公司: {companyData.companyName}</span>
                  <span>原职级: {companyData.jobPositionCn}</span>
                </div>
                <div className="forPromotiontext">
                  <span>员工编号: {companyData.staffNo}</span>
                  <span>原部门: {companyData.departmentName}</span>
                  <span>原岗位级别: {companyData.jobStationCn}</span>
                </div>
              </div>
            </FormItem>
            {/* <FormItem>
              
            </FormItem> */}
            <FormItem label="调整后公司">
              {getFieldDecorator('newCompanyId', {
                rules: [{ required: true, message: '请选择调整后公司' }]
              })(
                <Select placeholder="请选择调整后公司" 
                // filterOption={false}
                onSelect={onSelectProfile}
                // onSearch={companyNameSelect}
                optionFilterProp="children"
                autoFocus
                showSearch={true}>
                  {selectData.proCompany.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                </Select>
              )}
            </FormItem>
            <FormItem label="调整后部门">
              {getFieldDecorator('newDepartmentId', {
                rules: [{ required: true, message: '请输入调整后部门' }]
              })(
                <Select placeholder="请输入调整后部门" 
                disabled={proSectionFlag} 
                // filterOption={false}
                onSelect={onSelectProfileDepartment}
                // onSearch={departmentNameSelect}
                autoFocus
                optionFilterProp="children"
                showSearch={true}>
                  {proSection.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                </Select>
              )}
            </FormItem>
            <FormItem label="调整后职级">
              {getFieldDecorator('newJobPosition', {
                rules: [{ required: true, message: '请输入调整后职级' }]
              })(
                <Select placeholder="请选择调整后职级"
                // filterOption={false}
                onSelect={onSelectProfileJobPosition}
                // onSearch={jobPositionNameSelect}
                optionFilterProp="children"
                autoFocus
                showSearch={true}>
                  {selectData.proPosition.map(item => {
                    if (item.id <= companyData.jobPosition) {
                      return <Option key={item.id} value={item.id}>{item.name}</Option>
                    }
                  }
                  )}
                </Select>
              )}
            </FormItem>
            <FormItem label="调整后岗位级别">
              {getFieldDecorator('newJobStation', {
              })(
                <Select placeholder="请选择调整后岗位级别"
                // filterOption={false}
                onSelect={onSelectProfileJobStation}
                // onSearch={jobStationNameSelect}
                optionFilterProp="children"
                autoFocus
                showSearch={true}>
                  {selectData.proLevel.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                </Select>
              )}
            </FormItem>
            <FormItem label="调整后直接主管">
              {getFieldDecorator('managerStaffId', {
                rules: [{ required: true, message: '请输入调整后直接主管' }]
              })(
                <Select
                  placeholder="请选择调整后直接主管"
                  onDropdownVisibleChange={bool => bool && onStaffVisible()}
                  // filterOption={false}
                  onSelect={onSelectProfileManagerStaff}
                  // onSearch={managerStaffNameSelect}
                  optionFilterProp="children"
                  autoFocus
                  showSearch={true}
                >
                  {selectData.leaderList.map(item => <Option key={item.id} value={item.id}>{item.staffName}</Option>)}
                </Select>
              )}
            </FormItem>
            <FormItem label="附件">
              <div className="dragger-box" ref={draggerRef}>
                <Dragger
                  {...uploadFiles}
                >
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                  <p className="ant-upload-hint">
                    支持扩展名：GIF、PNG、JPG、PDF、doc、docx、csv、.xlsx、zip、.rar、JEPG 单个文件大小不超过5M，不超过5个附件。
                  </p>
                </Dragger>
              </div>
            </FormItem>
            <FormItem label="备注">
              {getFieldDecorator('remark')(
                <Input.TextArea placeholder="请输入备注" />
              )}
            </FormItem>
            <BtnGroup onCancel={() => setVisible(false)} loading={saveLoading} />
          </Form>
        </Spin>
      </Drawer>
    </>
  )
}

export default Form.create()(JobTransfer)