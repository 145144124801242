/*
 * @Author: zhuyan 
 * @Date: 2021-12-06 14:52:42 
 *  我的资产 => 系统账号管理
 */

import React, { useState, useEffect, useRef } from 'react';
import api from '@/api';
import { Table } from 'antd';
import CorpQuanQian from '@/components/CorpQuanQian';
import FiltersForm from '@/components/FiltersForm';
import { parseSearch } from '@/utils';
import Auth from '@/components/AuthMiddleware';

const SystemMy = (props) => {
  const { history, match, location } = props;
  const search = parseSearch(location.search);

  const initParams = {
    companyId: '',
    page: search.page || 1,
    limit: search.limit || 10,
  };

  const [states, setStates] = useState({
    dataSource: [], // 列表
    loading: true,
    total: 0, // 总条数
    filter: {
      project: [], // 项目
      groupList: [], // 事业群
      department: [] // 部门
    },
    // 筛选条件：列表接口参数
    params: initParams,
  });
  const { dataSource, filter, loading, total, params } = states;
  const ref = useRef()

  const [authList, setAuthList] = useState([]); // 按钮权限
  const [groupVal, setGroupVal] = useState(undefined)

  useEffect(() => {
    getList();
    const { page, limit, ...others } = params
  }, [params]);


  useEffect(() => {
    getPageAuth();
  }, []);

  // 获取列表
  const getList = () => {
    api
      .getSystemOwnerList(params)
      .then((res) => {
        setStates((current) => ({
          ...current,
          loading: false,
          dataSource: res.list,
          total: res.count,
        }));
      })
      .catch(() => {
        setStates((current) => ({
          ...current,
          loading: false,
        }));
      });
  };

  // 获取按钮权限
  const getPageAuth = () => {
    api.getPageAuth().then((list) => {
      setAuthList(list);
    });
  };

  // 项目
  const getsysProjectChilds = () => {
    api.getsysProjectChilds({ pid: 0 }).then(data => {
      setStates((current) => ({
        ...current,
        filter: {
          ...filter,
          project: data,
        },
      }));
    })
  }

  // 事业群
  const getSelectGroupList = () => {
    api.getSelectGroupList({ limit: global.paramsLimit }).then(data => {
      setStates((current) => ({
        ...current,
        filter: {
          ...filter,
          groupList: data.list,
        },
      }));
    })
  }

  // 部门
  const getSelectDepartmentList = (val) => {
    api.getSelectDepartmentList({ limit: global.paramsLimit, groupId: val }).then(data => {
      setStates((current) => ({
        ...current,
        filter: {
          ...filter,
          department: data.list,
        },
      }));
    })
  }

  // 点击选择所属公司
  const onChangeCorp = (data) => {
    setStates((current) => ({
      ...current,
      loading: true,
      params: {
        ...params,
        companyId: data.map((item) => item.id),
      },
    }));
    history.replace(match.path);
  };

  // 分页点击
  const onChangeTable = (pagination, filters, sorter) => {
    const { current: page, pageSize: limit } = pagination;
    setStates((current) => ({
      ...current,
      loading: true,
      params: {
        ...params,
        page: page,
        limit: limit,
      },
    }));
    history.replace(match.path + '?page=' + page + '&limit=' + limit);
  };

  // 点击筛选
  const onSubmit = (values) => {
    initParams.page = 1;
    initParams.limit = 10;
    setStates((current) => ({
      ...current,
      loading: true,
      params: {
        ...initParams,
        companyId: params.companyId,
        ...values,
      },
    }));
    history.replace(match.path);
  }

  const columns = [
    {
      title: '资产编号',
      dataIndex: 'number',
      valueType: 'Input',
      fixed: 'left',
      width: 120,
      fieldProps: {
        placeholder: '请输入资产编号',
      },
    },
    {
      title: '账号',
      dataIndex: 'accountName',
      valueType: 'Input',
      fieldProps: {
        placeholder: '请输入账号',
      },
    },
    {
      title: '项目名',
      dataIndex: 'projectId',
      valueType: 'Select',
      optionFilterProp: "children",
      fieldProps: {
        placeholder: '全部',
        showSearch: true,
        options: filter.project.map((item) => ({
          label: item.name,
          value: item.id,
        })),
        onDropdownVisibleChange: (open) => {
          if (open) {
            getsysProjectChilds();
          }
        },
      },
      render: (text, record) => record.projectName,
    },
    { title: '公司主体', dataIndex: 'companyName' },
    {
      title: '使用人',
      dataIndex: 'userName',
      valueType: 'Input',
      fieldProps: {
        placeholder: '请输入使用人',
      },
    },
    {
      title: '员工编号',
      dataIndex: 'userNumber',
      valueType: 'Input',
      fieldProps: {
        placeholder: '请输入员工编号',
      },
    },
    {
      title: '所属事业群',
      dataIndex: 'userGroupId',
      valueType: 'Select',
      optionFilterProp: "children",
      fieldProps: {
        placeholder: '全部',
        showSearch: true,
        options: filter.groupList.map((item) => ({
          label: item.name,
          value: item.id,
        })),
        dropdownMatchSelectWidth: false,
        onDropdownVisibleChange: (open) => {
          if (open) {
            getSelectGroupList();
          }
        },
        onChange: (val) => {
          ref.current.setFieldsValue({
            'userGroupId': val,
            'userDepartmentId': undefined,
          })
          setGroupVal(val)
        }
      },
      render: (text, record) => record.userGroupName,
    },
    {
      title: '所属部门',
      dataIndex: 'userDepartmentId',
      valueType: 'Select',
      optionFilterProp: "children",
      fieldProps: {
        placeholder: '全部',
        showSearch: true,
        options: filter.department.map((item) => ({
          label: item.name+ '（' + item.companyName + '）',
          value: item.id,
        })),
        dropdownMatchSelectWidth: false,
        onDropdownVisibleChange: (open) => {
          if (open) {
            getSelectDepartmentList(groupVal);
          }
        },
      },
      render: (text, record) => record.userDepartmentName,
    },
    { title: '密码', dataIndex: 'password' },
    {
      title: '开通时间',
      dataIndex: 'openingDate',
      valueType: 'RangePicker',
      search: {
        transform: (value) => {
          return {
            openingStartDate: value[0].format('YYYY-MM-DD'),
            openingEndDate: value[1].format('YYYY-MM-DD'),
          };
        },
      },
    },
    { title: '备注', dataIndex: 'remark' },
  ];

  return (
    <>
      <CorpQuanQian onChange={onChangeCorp} />
      <FiltersForm ref={ref} columns={columns} isNo={true} onSubmit={onSubmit} onReset={onSubmit} />
      <div className='line'></div>
      <div className='add-wrap'></div>
      <Table
        size='small'
        columns={columns}
        dataSource={dataSource}
        rowKey='id'
        loading={loading}
        className='askforleave-table'
        pagination={{
          pageSize: parseInt(params.limit),
          total: total,
          current: parseInt(params.page),
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        onChange={onChangeTable}
        // scroll={{ x: 'max-content' }}
        scroll={{ x: 1600, y: 500 }}
      ></Table>
    </>
  );
};

export default SystemMy
