import React, { useState } from 'react';
import { Icon, Button, DatePicker } from 'antd';

export default function ControlledDatePicker({ defaultValue, onOk }) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [loading, setLoading] = useState(false);
  const onConfirm = () => {
    if (onOk) {
      setLoading(true);
      onOk(value.format('YYYY-MM-DD'), onCancel);
    }
  };
  const onCancel = (rest = false) => {
    if (rest) {
      setValue(defaultValue);
    }
    setOpen(false);
    setLoading(false);
  };

  return (
    <DatePicker
      className='date-picker-noborder'
      dropdownClassName='date-picker-noborder-container'
      open={open}
      size='small'
      suffixIcon={<Icon type='edit' />}
      allowClear={false}
      showToday={false}
      format='YYYY-MM-DD'
      value={value}
      renderExtraFooter={() => (
        <>
          <Button size='small' onClick={() => onCancel(true)}>
            取消
          </Button>
          <Button size='small' type='primary' loading={loading} onClick={onConfirm}>
            确定
          </Button>
        </>
      )}
      onOpenChange={(status) => {
        if (status) {
          setOpen(true);
        }
      }}
      onChange={(date) => {
        setValue(date);
      }}
    />
  );
}
