/**
 * 模块名称: 消息列表
 * @author xuzhongyuan@372163.com
 */

import React, { useState, useEffect } from 'react'
import BtnGroup from '@/components/BtnGroup'
import { Table, Alert, Form, Input, Select, DatePicker, Button, message, Modal } from 'antd'
import api from '@/api'
import { Link } from 'react-router-dom'
import { parseSearch } from '@/utils'
import moment from 'moment'
import './style.scss'
import CryptoJS from 'crypto-js'

const Column = Table.Column
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker

let pageSize = 10
let currentPage = 1
let timeSort = ''

// 系统消息 (补充资料)
const newsObjNew = {
  12: '/protocol/invoice/apply?id=', // 发票
}

// 系统消息
const newsObj = {
  0: '/office/procedure/officeworkdetail?id=',
  10: '/hr/promotion/detail?id=',
  20: '/hr/jobTransfer/detail?id=',
  26: '/hr/employee/details?id=',
  25: '/helplist?id=',
  27: '/importDown/tolead?id=',
  9: '/hr/recruit/detail?id=',
  13: '/hr/dimission/detail?id=',
  8: '/attendance/appeal/details?id=',
  15: '/attendance/askforleave/addaskdetail?id=',
  5: '/attendance/individual?id=',
  30: '/helplist/details?id=',
  19: '',
  28: '',
  29: '',
  23: '',
  18: '',
  21: '',
  22: '',
  12: '/protocol/invoice/detail?id=',
  37: '/csr/addAudit/detail?id=',
  38: '/csr/nameChangeAudit/detail?id=',
  40: '/csr/vendorIdAudit/detail?id=',
  43: '/protocol/replenish/details?id=',
  44: '/protocol/contract/details?id=',
  45: '/protocol/quittance/details?id=',
  48: '/orderDirect/directDetail?id=',
  49: '/orderAgent/agentDetail?id=',
  39: '/account/creditapply/details?id=',
  32: '/fund/refundManage/details?id=',
  17: '/fund/moneyTransmit/details?id=',
  31: '/fund/moneyClaim/detail?id=',
  33: '/agent/agentNew/agentNewDetail?id=', // 代理商新增
  333: '/agent/nameManage/details?id=', // 代理商变更
  35: '/agent/manufacturer/details?id=',
  42: '/csr/turnAudit/detail?id=',
  34: '/agent/integralManage/details?id=',
  46: '/protocol/invoice/detail?id=', // 发票清理
  47: '/protocol/invoice/detail?id=', // 发票冲红
  36: '/agent/agentTransfer/detail?id=',
  50: '/orderDirect/refundDetail?id=', //直销退单
  51: '/orderAgent/refundDetail?id=', //代理商退单
  52: '/orderDirect/paybackDetail?id=', //直销负利还款
  53: '/orderAgent/paybackDetail?id=', //直销负利还款
  57: '/supplie/topUp/detail?id=', //系统充值
  58: '/office/costManagement/details?id=', //费用审批
  59: '/asset/purchase/details?id=', // 采购
  60: '/attendance/askforleave/addaskdetails?id=', //育儿假资格申请
  62: '/corpAccount/InternalAccount/details?id=', //公司内部往来款
  61: '/supplie/supplierContract/details?id=', //合同内容确认
  63: '/supplie/supplierContractSeal/details?id=' //合同盖章申请
}

let filter = {
  type: '',
  title: '',
  status: '',
  startTime: '',
  endTime: ''
}

const Message = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, getFieldsValue, resetFields } = props.form
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [typeList, setTyeList] = useState([])
  const [currentList, setCurrentList] = useState([])
  const search = parseSearch(location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.pageSize ? +search.pageSize : 10
    timeSort = ''
    filter = {
      type: '',
      title: '',
      status: '',
      startTime: '',
      endTime: ''
    }
    getData()
    api.getMessageTypes().then(data => {
      setTyeList(data)
    })
  }, [])

  const getData = () => {
    setLoading(true)
    api.getMessageList({ limit: pageSize, page: currentPage, sortMethod: timeSort, ...filter }).then(data => {
      setList(data.list)
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  const onChangeTable = (pagination, filters, sorter) => {
    switch (sorter.order) {
      case 'ascend':
        timeSort = 'asc'
        break
      case 'descend':
        timeSort = 'desc'
        break
      default:
        timeSort = ''
    }
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}`)
    getData()
  }

  const onSelectChange = data => {
    setCurrentList(data)
  }

  const formSubmit = (e) => {
    e.preventDefault()
    currentPage = 1
    history.replace(match.path)
    const vals = getFieldsValue()
    filter.type = vals.type
    filter.title = vals.title
    filter.status = vals.status
    if (vals.range) {
      filter.startTime = moment(vals.range[0]).format('YYYY-MM-DD')
      filter.endTime = moment(vals.range[1]).format('YYYY-MM-DD')
    }
    getData()
  }

  // 重置
  const onReset = () => {
    resetFields()
    filter = {
      type: '',
      title: '',
      status: '',
      startTime: '',
      endTime: ''
    }
    currentPage = 1
    history.replace(match.path)
    getData()
  }

  const onSetReaded = id => {
    if (!id && currentList.length === 0) {
      message.error('未勾选')
      return
    }
    api.setMessageReaded({ ids: id ? [id] : currentList }).then(() => {
      setCurrentList([])
      getData()
    })
  }

  const onShowModal = (targetId, companyName) => {
    // console.log(targetId, companyName)
    Modal.info({
      title: '消息内容',
      content: (
        <div>
          <p>{companyName}</p>
        </div>
      ),
      onOk() {
        api.setMessageReaded({ ids: [targetId] }).then(() => getData())
      }
    })
  }

  return (
    <>
      <div>
        <Form layout="inline" onSubmit={formSubmit}>
          <FormItem label="类型">
            {getFieldDecorator('type', { initialValue: '' })(
              <Select placeholder="请选择" style={{ width: 150 }}>
                <Option value={''}>全部</Option>
                {typeList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
              </Select>
            )}
          </FormItem>
          <FormItem label="标题">
            {getFieldDecorator('title', { initialValue: '' })(<Input placeholder="请输入" />)}
          </FormItem>
          <FormItem label="状态">
            {getFieldDecorator('status', { initialValue: '' })(
              <Select placeholder="请选择" style={{ width: 150 }}>
                <Option value="">全部</Option>
                <Option value="0">未读</Option>
                <Option value="1">已读</Option>
              </Select>
            )}
          </FormItem>
          <FormItem label="创建时间">
            {getFieldDecorator('range', { initialValue: '' })(<RangePicker />)}
          </FormItem>
          <FormItem>
            <BtnGroup right style={{ marginTop: 4 }} cancelName="重置" confirmName="查询" onCancel={onReset} />
          </FormItem>
        </Form>
      </div>
      <div className="mark-wrap"><Button type="primary" onClick={() => onSetReaded(null)}>标记已读</Button></div>
      <Alert className="alert-ant" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        dataSource={list}
        rowKey="id"
        className="table-ant"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        rowSelection={{
          fixed: true,
          selectedRowKeys: currentList,
          onChange: onSelectChange
        }}
        scroll={{ x: 1000 }}
        onChange={onChangeTable}
      >
        <Column title="类型" dataIndex="typeName" />
        <Column title="标题"
          dataIndex="title"
          render={(companyName, record) => {
            if (companyName == '您的审核需要补充资料' && record.type == 12) {
              return newsObjNew[record.type] ?
                <Link to={`${newsObjNew[record.type]}${record.targetId}&msgId=${record.id}`}>{companyName}</Link> :
                <div onClick={() => onShowModal(record.targetId, companyName)}>{companyName}</div>
            } else {
              return newsObj[record.type] ?
                <Link to={`${newsObj[record.type]}${record.targetId}&msgId=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.targetId+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{companyName}</Link> :
                <div onClick={() => onShowModal(record.targetId, companyName)}>{companyName}</div>
            }
          }}
        />
        <Column
          title="创建时间"
          sorter={true}
          dataIndex="addTime"
        />
        <Column title="状态" dataIndex="statusName" />
        <Column
          title="操作"
          key="set"
          fixed="right"
          width={100}
          render={(text, record) => text.status === 0 ? <span className="mark-btn" onClick={() => onSetReaded(text.id)}>标记已读</span> : null}
        />
      </Table>
    </>
  )
}

export default Form.create()(Message)
