/**
 * 模块名称: 晋升管理模块
 * @author zhuyan@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Promotion from './Promotion'
import PromotionDetail from './PromotionDetail'

const PromotionIndex = ({ match, history }) => {

  return (
    <div>
      <Switch>
        <Route path={match.path + '/detail'} component={PromotionDetail} />
        <Route component={Promotion} />
      </Switch>
    </div>
  )
}

export default PromotionIndex