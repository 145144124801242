/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-01-27 16:50:28
 */
import React, { useState } from 'react'
import { Modal, Button } from 'antd'

const PreviewImage = ({ src }) => {
  const [previewVisible, setPreviewVisible] = useState(false)
  const [showIcon, setShowIcon] = useState(false)

  const handleCancel = () => setPreviewVisible(false)

  const handlePreview = () => setPreviewVisible(true)

  const handleEnter = () => setShowIcon(true)

  const handleLeave = () => setShowIcon(false)

  if (src) {
    return (
      <>
        <div className="preview-image-super" onMouseEnter={handleEnter} onMouseLeave={handleLeave}>
          <img className="image-content" src={src} />
          {showIcon && (
            <div className="button-super">
              <Button type="primary" onClick={handlePreview}>预览</Button>
            </div>
          )}
        </div>
        <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={src} />
        </Modal>
      </>
    ) 
  } else {
    return <div className="preview-image-enpty" />
  }
}

export default PreviewImage
