/**
 * 模块名称: 待入职管理
 * @author yangzhihang@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from 'api'
import {
  Table,
  Form,
  Input,
  Button,
  Alert,
  Popconfirm,
  message
} from 'antd'
import { Link } from 'react-router-dom'
import Auth from 'components/AuthMiddleware'
import { parseSearch } from 'utils'

const { Column } = Table
const FormItem = Form.Item

let pageSize = 10
let currentPage = 1
let searchParamsTmp = {
  limit: pageSize, 
  page: currentPage,
}

let intervalRef = {}

const Premployee = props => {

  const { getFieldDecorator, validateFields, resetFields } = props.form
  const { history, match, location } = props
  const search = parseSearch(location.search)

  const [count, setCount] = useState(0)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)
  const [authList, setAuthList] = useState([])//按钮权限

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    searchParamsTmp = {
      limit: pageSize, 
      page: currentPage,
    }
    getList()
    getPageAuth()
    return () => {
      for(let i in intervalRef){ //eslint-disable-line
        clearTimeout(intervalRef[i])
      }
    }
  }, [])

  //按钮权限
  const getPageAuth = () => {
    api.getPageAuth().then(list => {
      setAuthList(list)
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }

  //获取列表
  const getList = () => {
    intervalRef.current = setTimeout(() => {
      searchParamsTmp.isOnJob = 2
      api.getManpower(searchParamsTmp).then(res => {
        setList(res.list)
        setCount(res.count)
        setLoading(false)
        clearTimeout(intervalRef.current)
      }).catch(() => { setLoading(false) })
    }, 500)
  }

  //重置
  const onReset = () => {
    resetFields()
    currentPage = 1
    history.replace(match.path)
    searchParamsTmp = {
    limit: pageSize, 
    page: currentPage,
    }
    getList()
  }
  //提交
  const formSubmit = evt => {
    evt.preventDefault()
    validateFields((err, vals) => {
      if(vals !== undefined){
        if(vals.staffName !== undefined)searchParamsTmp.staffName = vals.staffName
        if(vals.workMobile !== undefined)searchParamsTmp.workMobile = vals.workMobile
      }
      if(!err){
        searchParamsTmp.page = currentPage = 1
        history.replace(match.path)
        getList()
      }
    })
  }
  //点击分页
  const onChangeTable = (pagination, filters, sorter) => {
    searchParamsTmp.page = currentPage = pagination.current
    searchParamsTmp.limit = pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getList()
  }

  //删除
  const onDelete = (id) => {
    api.getDelStaff({ id: id }).then(() => {
      message.success('删除成功')
      getList()
    })
  }

  return (
  <>
    <div className="search-doc-wrap">
        <Form onSubmit={formSubmit}  {...formItemLayout}>
          <div className="form-box">
            <FormItem label="姓名">
              {getFieldDecorator('staffName', {
              })(<Input placeholder="请输入姓名" />)}
            </FormItem>
            <FormItem label="手机号">
              {getFieldDecorator('workMobile', {
              })(<Input placeholder="请输入手机号" />)}
            </FormItem>
          </div>
          <FormItem className="btns" label=" " colon={false}>
            <Button type="primary" htmlType="submit">查询</Button>
            <Button onClick={onReset}>重置</Button>
          </FormItem>
        </Form>
      </div>
      <div className="line"></div>
      <div>
        <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
        <Table
          size="small"
          dataSource={list}
          rowKey="id"  
          className="search-table"
          loading={loading}
          pagination={{
            pageSize,
            total: count,
            current: currentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          onChange={onChangeTable}
        >
          <Column title="真实姓名" dataIndex="trueName" width={140} render={(text, record) => (
            authList.includes('edit') ?
              <Auth auths={authList} code="edit">
                <Link target='_blank' to={`${match.path}/edit?id=${record.id}`}>{text}</Link>
              </Auth>
              : <div>{text}</div>
          )} />
          <Column title="手机号" dataIndex="workMobile" />     
          <Column title="身份证号" dataIndex="identityCardNo" />
          <Column title="性别" dataIndex="sexCn" />
          <Column title="年龄" dataIndex="age" />
          <Column title="提交时间" dataIndex="addTime" />
          <Column title="状态" dataIndex="isOnJob" render={() => (<div>待入职</div>)} />
          <Column title="操作" key="set" fixed="right" width={80} render={(text, record) => (
            <Auth auths={authList} code="delete">
              <Popconfirm title="请确认是否删除本条记录，删除后不可见？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                <span style={{ color: '#439DDD', cursor: 'pointer' }}>删除</span>
              </Popconfirm>
            </Auth>
            
          )} />
        </Table>
      </div>
  </> 
  )
}

export default Form.create()(Premployee)
 