/**
 * 模块名称: 待入职管理模块
 * @author yangzhihang@372163.com
 */

 import React from 'react'
 import { Switch, Route } from 'react-router-dom'
 import Premployee from './Premployee'
 import Edit from './Edit'
 
 const Index = ({ match }) => {
   return (
     <div>
       <Switch>
         <Route path={match.path + '/edit'} exact component={Edit} />
         <Route component={Premployee} />
       </Switch>
     </div>
   )
 }
 
 export default Index