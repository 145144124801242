/**
 * 模块名称: 组织架构
 * @author lids@372163.com
 */

import React, { useState, useEffect } from 'react'
import { Input, Button, Radio, message, Select, Modal } from 'antd'
import api from '@/api'
import * as d3 from 'd3'
import Auth from '@/components/AuthMiddleware'

var groupId = undefined, companyId = undefined, leaderId = undefined

const Structure = (props) => {

  const [groupList, setGroupList] = useState([])
  const [corpList, setCorpList] = useState([])
  const [leaderList, setLeaderList] = useState([])
  const [mode, setMode] = useState('0')
  const [d3Flag, setD3Flag] = useState(false)
  const [d3Loading, setD3Loading] = useState(false)
  const [leaderSelFlag, setLeaderSelFlag] = useState(true)
  const [selFlag, setSelFlag] = useState(true)
  const [rootName, setRootName] = useState('')
  const [modalVisible, setModalVisible] = useState(false)
  const [storedName, setStoredName] = useState('')
  const [fullFlag, setFullFlag] = useState(false)
  const [authList, setAuthList] = useState([])

  const [ifIe, setIfIe] = useState(Boolean)

  useEffect(() => {
    //判断是否为IE
    if (!!window.ActiveXObject || "ActiveXObject" in window){
      setIfIe(true)
    } else{
      setIfIe(false)
    }
    groupId = leaderId = undefined
    let structureMain = document.querySelector('.structure-main')
    structureMain.parentNode.style.height = "100%"
    api.getPageAuth().then(list => {
      setAuthList(list)
    })
    return () => {
      let sider = document.querySelector('.ant-layout-sider')
      let header = document.querySelector('.ant-layout-header')
      sider.style.display = 'block'
      header.style.display = 'flex'
      setFullFlag(false)
    }
  }, [])
  
  const rect = () => {

    if(groupId === undefined && leaderId === undefined){
      setD3Loading(false)
      if(mode === '0' && groupId === undefined){
        message.info('请选择事业群')
        return
      }
      if(mode === '1'){
        if(leaderSelFlag){
          message.info('请选择公司')
          return
        }
        if(leaderId === undefined){
          message.info('请选择管理人员')
          return
        } 
      }
    }
    let params = {}
    if(groupId !== undefined) params = { groupId: groupId }
    if(leaderId !== undefined) params = { leaderId: leaderId }

    api.getStructure(params).then(root => {

      setD3Loading(false)

      let structureContent = document.querySelector('.content-box')
      structureContent.innerHTML = ''//清空绘制区域
      
      if(JSON.stringify(root) === '[]'){
        setD3Flag(false)
        if(mode === '0')message.info('该事业群没有组织架构')
        if(mode === '1')message.info('该人员下没有组织架构')
        return
      }
     
      var len = 0

      root.forEach(item => { len += item.allchildrenNum })

      var width = 600, height = 600

      if(len < 20){
        width = height = 800
      }else {
        width = height = 1100
      }
      
      //拖拽缩放
      var zoom = d3.zoom()
        .scaleExtent([0.1, 15])
        .on("zoom", zoomed)

      function zoomed(){
        map.attr("transform", "translate(" + d3.event.transform.x + "," + d3.event.transform.y + ") scale(" + d3.event.transform.k + ")")
      }
      
      //创建svg
      var svg = d3.select(".content-box").append("svg")
          .attr("width", width)
          .attr("height", height)
          .attr("style", "margin-left: 30px; padding-left: 5px")
          .classed("mysvg", true)
          //.attr("preserveAspectRatio", "xMidYMid meet")
          //.attr("viewBox", `0 0 ${width} ${height}`)
          //.attr("viewBox", `0 0 1000 1000`)
          .call(zoom)

      var map = svg.append("g")
      
      // var nodes = cluster.nodes(json)
      // var links = cluster.links(nodes)
      let parmas = {
        pName: rootName,
        allchildrenNum: 0,
        children: root
      }
      if(len < 60){

        //缩放宽高度
        var xScale = d3.scaleLinear()
          .domain([1, 3])
          .range([32,50])

        //创建一个树状图
        var cluster = d3.tree()
          .size([width, height])
          .separation(() => {
            return 30
          })

        const treeRoot = d3.hierarchy(parmas)
        cluster(treeRoot)
        const nodes = treeRoot.descendants()
        const links = treeRoot.links()
        var link = map.selectAll(".link")//eslint-disable-line
            .data(links)
            .enter()
            .append("path")
            .attr("class", "link")
            .attr("d", function(d){
              return "M" + d.source.y + " " + d.source.x +
              "L" + (d.source.y + 180) + " " + d.source.x +
              " L" + (d.source.y + 180) + " " + d.target.x + " L" +
              d.target.y + " " + d.target.x

            })
            .attr("style",function(){
              return "stroke:#F7881F;fill: none; stroke: #ccc; stroke-width: 1.5px;"
            })

        var node = map.selectAll(".node")
            .data(nodes)
            .enter()
            .append("g")
            .attr("transform", function(d) { 
              return "translate(" + (d.y+ 0) + "," + (d.x+ -20) + ")"
            })

        node.append("rect")
          .attr("class", "rect")
          .attr("x",0)
          .attr("y",0)
          .attr("height", 32)
          .transition()
            .duration(800)
            .attr("height", function (d) {return xScale(1)})
          .attr("style", "fill:#1890ff; width: 160px;")

        node.append("text")
          .attr("dx", function(d) { 
            return 80
          })
          .attr("class", "text")
          .attr("dy", 20)
          .style("text-anchor", function(d) { 
            return "middle"
          })
          .style("fill", "#fff")
          .text(function(d) { 
            return d.data.pName
          })

        node.append("text")
          .attr("dx", function(d) { 
            return 80
          })
          .attr("class", "text")
          .attr("dy", 20)
          .style("text-anchor", function(d) { 
            return "middle"
          })
          .style("fill", "#fff")
          .text(function(d) { 
            return d.data.pName ? '' : (d.data.jobPositionCn ? d.data.jobPositionCn : '其他')
          })

        node.append("text")
          .attr("dx", function(d) { 
            return 80
          })
          .attr("dy", 50)
          .style("text-anchor", function(d) { 
            return "middle"
          })
          .style("fill", "#1890ff")
          .text(function(d) { 
            if(d.data.leaderName || d.data.leaderName === ""){
              if(d.data.leaderName === "") d.data.leaderName = "员工"
              return d.data.leaderName + (d.data.allchildrenNum ? "(" + d.data.allchildrenNum + "人)" : "")
            }
            if(d.data.staffName || d.data.staffName === ""){
              if(d.data.staffName === "") d.data.staffName = "员工"
              return d.data.staffName + (d.data.allchildrenNum ? "(" + d.data.allchildrenNum + "人)" : "")
            }
            if(d.data.pName) return ""
            return "员工"
          })
      }else{
    	
    	//创建一个hierarchy layout
    	var hierarchyData = d3.hierarchy(parmas)
    		.sum(function(d){
    			return d.value
    		})
    		
    	//创建一个树状图
    	var tree = d3.tree()
    		.nodeSize([120, 450])
    		.separation(function(a, b){
    			return (a.parent == b.parent ? 1 : 2) / a.depth//eslint-disable-line
    		})
    	
    	//初始化树状图，也就是传入数据,并得到绘制树基本数据
    	var treeData = tree(hierarchyData)
    	//得到节点
    	var nodes = treeData.descendants()
    	var links = treeData.links()
    	
    	//创建一个贝塞尔生成曲线生成器
    	var Bézier_curve_generator = d3.linkHorizontal()
    		.x(function(d) { return d.y })
    		.y(function(d) { return d.x })
    		
    	//绘制边
    	map.append("g")
    		.selectAll("path")
    		.data(links)
    		.enter()
    		.append("path")
    		.attr("d",function(d){
    			var start = {x:d.source.x, y:d.source.y}
    			var end = {x:d.target.x, y:d.target.y}
    			return Bézier_curve_generator({ source:start, target:end })
    		})
    		.attr("fill", "none")
    		.attr("stroke", "#1890ff")
    		.attr("stroke-width", 1)
    		.attr("transform",function(d){
    			var cx = 0
    			var cy= 0
    			return `translate(${cy},${cx})`
    		})
    		
    	//绘制节点和文字
    	//先创建用以绘制每个节点和对应文字的分组<g>
    	var gs = map.append("g")
    		.selectAll("g")
    		.data(nodes)
    		.enter()
    		.append("g")
    		.attr("transform",function(d){
    			var cx = d.x
    			var cy= d.y
    			return `translate(${cy},${cx})`
    		})
    	//绘制节点
    	gs.append("circle")
    		.attr("r", 6)
    		.attr("fill", "white")
    		.attr("stroke", "blue")
    		.attr("stroke-width", 1)
    		
    	//文字
    	gs.append("text")
        .attr("fill","black")
    		.attr("x",function(d){
    			// return d.children ? -10 : 8
          return 8
    		})
    		.attr("y", function(d){
          // return d.children ? -5 : -25
          return -25
        })
    		.attr("dy", 30)
    		.text(function(d){
          if(d.data.pName || d.data.pName === ""){
            return d.data.pName
          }
          if(d.data.leaderName || d.data.leaderName === ""){
            if(d.data.leaderName === "") return d.data.leaderName = "员工" + (d.data.allchildrenNum ? " (" + d.data.allchildrenNum + "人)" : "")
            return d.data.jobPositionCn + "丨" + d.data.leaderName + (d.data.allchildrenNum ? " (" + d.data.allchildrenNum + "人)" : "")
          }
    			if(d.data.staffName || d.data.staffName === ""){
            if(d.data.staffName === "") return d.data.staffName = "员工" + (d.data.allchildrenNum ? " (" + d.data.allchildrenNum + "人)" : "")
            return d.data.jobPositionCn + "丨" + d.data.staffName + (d.data.allchildrenNum ? " (" + d.data.allchildrenNum + "人)" : "")
          }
          return "员工"
    		})
      }
    }).catch(() => { setD3Loading(false) })
  }
    
  const getGroupList = () => {
    api.getSelectGroupList({ limit: global.paramsLimit }).then(data => setGroupList(data.list)) //事业群
  }

  const getGroup = data => {
    //console.log(data)
    groupId = data.key
    setRootName(data.label)
    companyId = undefined
    leaderId = undefined
  }

  const getCorpList = () => {
    api.getSelectCompanyList({ limit: global.paramsLimit }).then(data => setCorpList(data.list)) //公司
  }

  const getCorp = data => {
    companyId = data.key
    setRootName(data.label)
    groupId = undefined
    leaderId = undefined
    setLeaderSelFlag(false)
    setSelFlag(false)
    setLeaderList([])
    let timer = setTimeout(() => { 
      setSelFlag(true)
      clearTimeout(timer)
    }, 200)
  }

  const getLeaderList = () => {
    api.getLeaderList({ limit: global.paramsLimit, companyId: companyId, minJobPosition: 5 }).then(data => setLeaderList(data.list)) //管理人员
  }

  const getLeader = id => {
    leaderId = id
    groupId = undefined
  }

  const setFullScreen = () => {
    let sider = document.querySelector('.ant-layout-sider')
    let header = document.querySelector('.ant-layout-header')
    sider.style.display = 'none'
    header.style.display = 'none'
    setFullFlag(true)
  }
  const setOriginScreen = () => {
    let sider = document.querySelector('.ant-layout-sider')
    let header = document.querySelector('.ant-layout-header')
    sider.style.display = 'block'
    header.style.display = 'flex'
    setFullFlag(false)
  }

  const handleModeChange = e => {
    const mode = e.target.value
    groupId = undefined
    leaderId = undefined
    companyId = undefined
    setMode(mode)
    setLeaderSelFlag(true)
  }

  const setD3Rect = () => {
    setD3Loading(true)
    setD3Flag(true)
    rect()
  }
  const saveSvg = () => {
    setModalVisible(true)
  }

  const oprateHandleOk = () => {
    if(d3Flag){
      var serializer = new XMLSerializer()
      var svg1 = document.querySelector('svg.mysvg')
      var toExport = svg1.cloneNode(true)
      var bb = svg1.getBBox()
      toExport.setAttribute('viewBox', bb.x + ' ' + (bb.y-30) + ' ' + (bb.width+80) + ' ' + (bb.height+80))
      toExport.setAttribute('width', bb.width)
      toExport.setAttribute('height', bb.height)

      //var source = '<?xml version="1.0" standalone="no"?>\r\n' + serializer.serializeToString(d3.select('svg.mysvg').node())
      var source = '<?xml version="1.0" standalone="no"?>\r\n' + serializer.serializeToString(toExport);
      var image = new Image()
      image.src = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(source)
      var canvas = document.createElement("canvas")
      // const pNode = d3.select('svg.mysvg').node().parentNode
      canvas.width = bb.width
      canvas.height = bb.height
      var context = canvas.getContext("2d")
      context.fillStyle = '#fff'//#fff设置保存后的PNG 是白色的  
      context.fillRect(0, 0, 10000, 30000)

      image.onload = function() {  
        context.drawImage(image, 0, 0)
        var link = document.createElement("a")
        var imgData = canvas.toDataURL("image/png")
        var strDataURI = imgData.substr(22, imgData.length)//eslint-disable-line
        var blob = dataURLtoBlob(imgData)
        var objurl = URL.createObjectURL(blob)

        link.download = storedName.length ? `${storedName}.png` : `${rootName}.png`
        link.href = objurl
        link.click()
        setModalVisible(false)
      }
      const dataURLtoBlob = function(dataurl) {
        let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n)
        while(n--){
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new Blob([u8arr], {type:mime})
      }
    }
  }
  const oprateHandleCancel = () => {
    setModalVisible(false)
    setStoredName('')
  }
  const getAreaTxt = (e) => {
    if(e.target.value.length){
      setStoredName(e.target.value)
    }
  }

  return (
    <>
      {ifIe === true && <div>
        <div>当前浏览器环境不支持组织架构图的部分功能</div>
        <div>请使用360浏览器极速模式、搜狗浏览器高速模式、QQ浏览器极速模式、GoogleChrome浏览器等访问系统请点击地址栏右侧“闪电”图标切换浏览器模式</div>
      </div>}
      {ifIe === false && <div className="structure-main">
        <div className="structure-control">
          <div className="structure-tabs">
            <Radio.Group onChange={handleModeChange} value={mode} className="radio-btn-group">
              <Radio.Button className="radio-btn" value="0">按事业群</Radio.Button>
              <Radio.Button className="radio-btn" value="1">按个人</Radio.Button>
            </Radio.Group>
          </div>
          {mode === '0' && <div className="stucture-con-section">
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              placeholder="请选择事业群"
              onDropdownVisibleChange={bool => bool && getGroupList()}
              onChange={getGroup}
              labelInValue
              style={{width: '100%'}}
            >
              {
                groupList.map((item, index) => {
                  return <Select.Option key={item.id}>{item.name}</Select.Option>
                })
              }
            </Select>
          </div>}
          {mode === '1' && <div className="stucture-con-section">
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              placeholder="请选择公司"
              labelInValue
              onDropdownVisibleChange={bool => bool && getCorpList()}
              onChange={getCorp}
              style={{width: '100%'}}
              disabled={mode === '0'}
            >
              {
                corpList.map((item, index) => {
                  return <Select.Option key={item.id}>{item.name}</Select.Option>
                })
              }
            </Select>
          </div>}
          {mode === '1' && selFlag && <div className="stucture-con-section">
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              placeholder="请选择管理人员" 
              onDropdownVisibleChange={bool => bool && getLeaderList()}
              onChange={getLeader}
              style={{width: '100%'}}
              disabled={leaderSelFlag}
            >
              {
                leaderList.map((item, index) => {
                  return <Select.Option key={item.id}>{item.staffName}</Select.Option>
                })
              }
            </Select>
          </div>}
          <div className="stucture-con-section">
            <Button style={{width: '100%'}} type="primary" onClick={setD3Rect} loading={d3Loading}>生成组织结构图</Button>
          </div>
          <div className="stucture-con-section">
            {!fullFlag && <Button style={{width: '100%'}} type="primary" onClick={setFullScreen}>全屏</Button>}
            {fullFlag && <Button style={{width: '100%'}} type="primary" onClick={setOriginScreen}>还原</Button>}
          </div>
          <Auth auths={authList} code="operate">
            <div className="stucture-con-section">
              <Button style={{width: '100%'}} type="" disabled={!d3Flag} onClick={saveSvg}>保存图片</Button>
            </div>
          </Auth>
        </div>
        <div className="structure-content">
          {!d3Flag && <div className="no-content">绘图板区域</div>}
          {d3Flag && <div className="content-box"></div>}
        </div>
      </div>
      }
      <Modal
        title="保存图片"
        visible={modalVisible}
        onOk={oprateHandleOk}
        onCancel={oprateHandleCancel}
        destroyOnClose={true}
      >
        <div className="task-modal-content">
          <span className="label">图片名称:</span>
          <div className="content-area">
            <Input placeholder="请输入图片名称" defaultValue={storedName} onChange={getAreaTxt} style={{width: "100%"}} />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Structure