/**
 * 模块名称: 岗位级别管理
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Drawer,
  Form,
  Button,
  Input,
  Select,
  message,
  Icon,
  Popconfirm,
  Spin,
  Tooltip
} from 'antd'
import BtnGroup from '@/components/BtnGroup'
import Auth from '@/components/AuthMiddleware'
import { parseSearch } from '@/utils'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
let editId = '' //编辑使用
let pageSize = 10
let currentPage = 1

let filter = {
  joblevel: '',
  jobStation: '',
  name: ''
}

const JobStationLevel = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [visible, setVisible] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [formLoading, setformLoading] = useState(false)//eslint-disable-line
  const search = parseSearch(location.search)
  const [staffJobLevel, setStaffJobLevel] = useState([])
  const [staffJobStation, setStaffJobStation] = useState([])
  const [staffJobLevelValue, setStaffJobLevelValue] = useState(undefined)
  const [staffJobStationValue, setStaffJobStationValue] = useState(undefined)
  const [authList, setAuthList] = useState([])

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    filter = {
      joblevel: '',
      jobStation: '',
      name: ''
    }
    getJobLevel() //岗位级别接口
    getJobLevelCommon() //公共接口
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  //获取岗位级别列表
  const getJobLevel = () => {
    setLoading(true)
    api.getJobLevel({
      joblevel: filter.joblevel,
      jobStation: filter.jobStation,
      name: filter.name,
      limit: pageSize,
      page: currentPage
    }).then(data => {
      setList(data.list)
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  //调公共接口
  const getJobLevelCommon = () => {
    api.getCommon({ type: 'staff_job_level' }).then(data => setStaffJobLevel(data)) //岗位类别
    // api.getCommon({ type: 'staff_job_station' }).then(data => setStaffJobStation(data)) //岗位级别
    api.getCommon({ type: 'department_type' }).then(data => setStaffJobStation(data)) // 部门类型
  }

  // 提交添加
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        if (editId) {
          vals.id = editId
        }
        setSaveLoading(true)
        api.saveJobStationLevelInfo(vals).then(() => {
          setSaveLoading(false)
          setVisible(false)
          message.success('保存成功')
          getJobLevel()
        }).catch(() => setSaveLoading(false))
      }
    })
  }

  //删除按钮
  const onDelete = (id) => {
    api.saveJobStationLevelUpdateIsDel({ id }).then(() => {
      message.success('删除成功')
      getJobLevel()
    })
  }

  //编辑按钮
  const onEdit = (data) => {
    setVisible(true)
    editId = data.id
    setFieldsValue({
      name: data.name,
      joblevel: data.joblevel,
      jobStation: data.jobStation
    })
  }

  // 添加按钮
  const onAddCorp = () => {
    editId = ''
    setVisible(true)
    resetFields()
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getJobLevel()
  }

  //互换岗位级别排序
  const onSort = (id, flow) => {
    api.saveJobStationLevelUpdateSort({ id, flow }).then(getJobLevel)
  }

  const afterVisibleChange = (isOpen) => {
    if (isOpen) {

    }
  }

  //点击筛选时
  const joblevelInp = useRef(null)
  const onSearch = () => {
    filter.joblevel = staffJobLevelValue === undefined ? '' : staffJobLevelValue
    filter.jobStation = staffJobStationValue === undefined ? '' : staffJobStationValue
    filter.name = joblevelInp.current.value
    currentPage = 1
    history.replace(match.path)
    getJobLevel()
  }

  //点击重置时
  const onReset = () => {
    filter = {
      joblevel: '',
      jobStation: '',
      name: ''
    }
    joblevelInp.current.value = ''
    setStaffJobLevelValue(undefined)
    setStaffJobStationValue(undefined)
    currentPage = 1
    history.replace(match.path)
    getJobLevel()
  }

  //岗位类别下拉时获取里面的值
  const onStatusSelectJobLevel = (value) => {
    setStaffJobLevelValue(value)
  }

  //岗位级别下拉时获取里面的值
  const onStatusSelectJobStation = (value) => {
    setStaffJobStationValue(value)
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }

  // 输入岗位级别回车
  const onKeyup = (e) => {
    if (e.keyCode === 13) {
      onSearch()
    }
  }

  return (
    <>
      <div className="filter-wrap" style={{ marginBottom: '30px' }}>
        <div className="filter-item" style={{ marginRight: '10px' }}>其他选项：</div>
        <div className="filter-item">
          <label style={{ width: 80 }} htmlFor="">岗位类型：</label>
          <Select placeholder="全部" value={staffJobLevelValue} style={{ width: 150 }} onSelect={onStatusSelectJobLevel} >
            {
              staffJobLevel.map((v, i) => {
                return <Option value={v.id} key={i}>{v.name}</Option>
              })
            }
          </Select>
        </div>
        <div className="filter-item" style={{ marginLeft: 25 }}>
          <label htmlFor="" style={{ width: 80 }}>部门类型：</label>
          <Select placeholder="全部" value={staffJobStationValue} style={{ width: 150 }} onSelect={onStatusSelectJobStation} >
            {
              staffJobStation.map((v, i) => {
                return <Option value={v.id} key={i}>{v.name}</Option>
              })
            }
          </Select>
        </div>
        <div className="filter-item" style={{ marginLeft: 25 }}>
          <label style={{ width: 100 }} htmlFor="">岗位级别：</label>
          <input className="ant-input" placeholder="请输入岗位级别" ref={joblevelInp} onKeyUp={onKeyup} />
        </div>
        <BtnGroup cancelName="重置" confirmName="筛选" onConfirm={onSearch} onCancel={onReset} />
      </div>
      <div className="line"></div>
      <div className="add-wrap">
        <Auth auths={authList} code="add">
          <Button type="primary" icon="plus" onClick={onAddCorp}>添加</Button>
        </Auth>
      </div>
      <Table
        size="middle"
        dataSource={list}
        rowKey="id"
        className="corp-table"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        // scroll={{ x: 1400 }}
        onChange={onChangeTable}
      >
        <Column title="ID" dataIndex="id" fixed="left" width={100} />
        <Column title="岗位级别" dataIndex="name" fixed="left" width={200} />
        <Column title="岗位类型" dataIndex="joblevelCn" />
        <Column title="部门类型" dataIndex="jobStationCn" />
        <Column title="排序" key="sort" render={(text, record, index) => (
          <span className="caret-wrap">
            <Icon type="caret-up" onClick={() => onSort(text.id, 'up')} />
            <Icon type="caret-down" onClick={() => onSort(text.id, 'down')} />
          </span>
        )} />
        <Column title="操作" key="set" fixed="right" width={100} render={(text, record) => (
          <>
            <Auth auths={text.operateAuth} code="edit">
              <Tooltip title="编辑">
                <Icon className="operate-icon" type="edit" onClick={() => onEdit(record)} style={{ color: '#1890FF' }} />
              </Tooltip>
            </Auth>
            {' '}
            <Auth auths={text.operateAuth} code="delete">
              <Tooltip title="删除">
                <Popconfirm title="确认删除吗？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                  <Icon className="operate-icon" type="delete" style={{ color: 'red' }} />
                </Popconfirm>
              </Tooltip>
            </Auth>
          </>
        )} />
      </Table>
      <Drawer
        title={editId == ''? "添加岗位级别":"编辑岗位级别"}
        width={600}
        onClose={() => setVisible(false)}
        visible={visible}
        afterVisibleChange={afterVisibleChange}
      >
        <Spin spinning={formLoading}>
          <Form onSubmit={formSubmit} {...formItemLayout}>
            <FormItem label="岗位级别">
              {getFieldDecorator('name', {
                rules: [{ required: true, message: '请输入岗位级别' }]
              })(<Input placeholder="请输入岗位级别" />)}
            </FormItem>
            <FormItem label="岗位类型">
              {getFieldDecorator('joblevel', {
                rules: [{ required: true, message: '请选择岗位类型' }]
              })(
                <Select placeholder="请选择岗位类型">
                  {staffJobLevel.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                </Select>
              )}
            </FormItem>
            <FormItem label="部门类型">
              {getFieldDecorator('jobStation', {
                rules: [{ required: true, message: '请选择部门类型' }]
              })(
                <Select placeholder="请选择部门类型">
                  {staffJobStation.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                </Select>
              )}
            </FormItem>
            <BtnGroup onCancel={() => setVisible(false)} loading={saveLoading} />
          </Form>
        </Spin>
      </Drawer>
    </>
  )
}

export default Form.create()(JobStationLevel)